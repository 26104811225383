import { BADGETYPE, Badge, Button, CheckBoxComponent, IconInput, Typhography } from 'dfh-ui-library';
import moment from 'moment';
import React, { useState } from 'react';
import { ResourceCardProps } from 'utils/types/patient.type';

const Card: React.FC<ResourceCardProps> = ({
    title,
    dateCreated,
    showPreview,
    fileId,
    uploadType,
    _id,
    onCheckboxChange,
}) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(false);

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        _id && onCheckboxChange && onCheckboxChange(_id, newCheckedState);
    };
    return (
        <div className='border rounded-lg p-4 mb-4 bg-[#F9FAFB]'>
            <div className='flex justify-between  gap-2'>
                <div className='flex  justify-between'>
                    <div className='flex '>
                        <div className='rounded-lg mr-4'>
                            <CheckBoxComponent
                                onChange={handleCheckboxChange}
                                checked={isChecked}
                                id={_id}
                                addtionalClasses=''
                            />
                        </div>
                        <div>
                            <h2 className='text-21s font-medium'>{title}</h2>
                            <p className='text-12s text-gray-500 font-normal'>
                                {moment(dateCreated).format('MM/DD/YYYY')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <Badge
                        badgeType={BADGETYPE.BUTTON}
                        colorVariant={'Upload' === 'Upload' ? 'darkGreen' : 'darkBlue'}
                        additionalClasses='flex'
                    >
                        {'Upload'}
                    </Badge>
                    <div className='flex justify-end items-center gap-4'>
                        {
                            <Button
                                buttonLabel='Preview'
                                data-testId='InputField-id'
                                iconAlignment='left'
                                iconType='PreviewIcon'
                                isIconEnabled
                                variants='primaryWhite'
                                onClick={() => showPreview && fileId && showPreview(fileId, uploadType)}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const ExistingCardList: React.FC<any> = ({ cardData, ...props }) => {
    const [searchText, setSearchText] = useState('');

    const setSearchTextOnChange = (e: any) => {
        setSearchText(e.target.value);
    };

    // Filter cardData based on the search text
    const filteredCardData = cardData.filter((card: any) =>
        card.resourceName.toLowerCase().includes(searchText.toLowerCase()),
    );

    return (
        <div className='w-full'>
            <IconInput
                type='text'
                value={searchText}
                onChange={setSearchTextOnChange}
                isIconLeft={true}
                leftIcon={'Search'}
                iconClassLeft='absolute top-3 left-4'
                labelSpanClasses='text-21s'
                additionalClasses='mt-0 h-10 w-full mb-4'
                placeholder='Search document'
            />
            <Typhography component='div' additionalClasses='max-h-[50vh] overflow-hidden overflow-y-auto  p-2'>
                {filteredCardData.length > 0 ? (
                    filteredCardData.map((card: any, index: any) => (
                        <Card
                            key={index}
                            title={card.resourceName}
                            fileSize={'0KB'}
                            dateCreated={card.uploadDate}
                            isShowPreview={card.isShowPreview}
                            fileId={card.resourceLink}
                            showPreview={props.showPreview}
                            uploadType={card.uploadType}
                            _id={card._id}
                            onCheckboxChange={props?.handleCheckboxChange}
                        />
                    ))
                ) : (
                    <p>No matching documents found.</p>
                )}
            </Typhography>
        </div>
    );
};

export default ExistingCardList;
