export const language = {
    patientsEnrolled: {
        title: 'Total patients',
        legendAssigned: 'Assigned',
        legendUnassigned: 'Unassigned',
        excelSheetTitle: 'Total patients',
    },
    patientsScreenedAtLeastOnce: {
        title: 'Patients screened at least once',
        excelSheetTitle: 'Patients screened at least once',
    },
    screenerOutcomes: {
        title: 'Screener outcomes',
        legendGreaterThanOneChallenge: '≥ 1 Challenge',
        legendNegative: 'Negative',
        excelSheetTitle: 'Screener outcomes',
    },
    averageNumberOfNewChallengesPerPatient: {
        title: 'Average challenges per patient',
        excelSheetTitle: 'Average challenges per patient',
    },
    totalChallengesResolved: {
        title: 'Total challenges resolved',
        excelSheetTitle: 'Total challenges resolved',
    },
    patientsWithFoodInsecurity: {
        title: 'Food insecurity',
        excelSheetTitle: 'Food insecurity',
    },
    patientsWithHousingInsecurity: {
        title: 'Housing insecurity',  
        excelSheetTitle: 'Housing insecurity',
    },
    patientsWithTransportationInsecurity: {
        title: 'Transportation insecurity',
        excelSheetTitle: 'Transportation insecurity',
    },
    patientsWithIncomeInsecurity: {
        title: 'Income insecurity',
        excelSheetTitle: 'Income insecurity',
    },
};
