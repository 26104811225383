import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ApiResponse,
    IChallengeRequestParams,
    IChallengeTimelineRequestParams,
    IListRequestParams,
} from '../utils/types/api.type';
import { axiosBase, baseQueryWithReAuth } from './interceptors';
import {
    IPatient,
    IPatientAssign,
    IPatientRequest,
    IServeyNoteRequest,
    ISolutionRequest,
    ITodoToggleRequestParams,
} from 'utils/types/patient.type';
import {
    IExistingResourceRequest,
    IImageCreateRequest,
    IPatientResourceRequest,
    IResourcesCreateRequest,
} from 'utils/types/image.type';
import { AxiosProgressEvent } from 'axios';

interface DownloadRequest {
    req: string;
    type: string;
}

const baseQuery = baseQueryWithReAuth;

export const patientAPI = createApi({
    reducerPath: '/patientApi',
    baseQuery,
    tagTypes: ['Patient'],
    endpoints: (builder) => ({
        patientAdd: builder.mutation<ApiResponse, IPatient>({
            query: (data) => ({
                url: '/patients',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Patient'],
        }),
        patientEdit: builder.mutation<ApiResponse, IPatient>({
            query: (data) => ({
                url: `/patients/${data._id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Patient'],
        }),
        patientEditAssign: builder.mutation<ApiResponse, IPatientAssign>({
            query: (data) => ({
                url: `/patients/${data._id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Patient'],
        }),
        patientEditAssignBulk: builder.mutation<ApiResponse, IPatientAssign>({
            query: (data) => ({
                url: `/patients`,
                method: 'PUT',
                body: data,
            }),
        }),
        patientDelete: builder.mutation<ApiResponse, IPatientRequest>({
            query: (data) => ({
                url: `/patients/${data.id}`,
                method: 'DELETE',
            }),
        }),
        makeUrgent: builder.mutation<ApiResponse, any>({
            query: (data) => ({
                url: `/challenges/${data.id}`,
                method: 'PUT',
                body: data,
            }),
        }),
        saveNotes: builder.mutation<ApiResponse, any>({
            query: (data) => ({
                url: `/challenges/${data.id}`,
                method: 'PUT',
                body: { ...data, note: data.note.note },
            }),
        }),
        patientGet: builder.query<ApiResponse, IPatientRequest>({
            query: (data) => {
                return {
                    url: `/patients/${data.id}`,
                    method: 'GET',
                };
            },
        }),
        patientChallenge: builder.query<ApiResponse, IChallengeRequestParams>({
            query: (req) => ({
                url: `/challenges?patientId=${req.patientId}&unresolved=${req.isResolved}`,
                method: 'GET',
            }),
        }),
        patientList: builder.query<ApiResponse, IListRequestParams>({
            query: (req) => ({
                url: `/patients?sortby=${req.sortby}&skip=${req.skip}&limit=${req.limit}&filters=${req.filters}`,
                method: 'GET',
            }),
        }),
        getSolutionData: builder.query<ApiResponse, ISolutionRequest>({
            query: (req) => ({
                url: `/solutions?patientChallengeId=${req.patientChallengeId}&challengeId=${req.challengeId}`,
                method: 'GET',
            }),
        }),
        getResources: builder.query<ApiResponse, string>({
            query: (req) => ({
                url: `/patient-resources?patientId=${req}`,
                method: 'GET',
            }),
        }),
        downloadResources: builder.query<ApiResponse, DownloadRequest>({
            query: ({ req, type }) => ({
                url: `/files?fileId=${req}&type=${type}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
        }),
        getReleaseInfoFiles: builder.query<ApiResponse, IResourcesCreateRequest>({
            query: (data) => ({
                url: `/files?fileId=${data.fileId}&type=${data.type}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
        }),
        saveSolutionNote: builder.mutation<ApiResponse, any>({
            query: (data) => {
                return {
                    url: `solutions/${data.solutionId}/${data.solutionVariantId}`,
                    method: 'POST',
                    body: {
                        ...data,
                        note: data.note.note,
                        shouldUpdateLastContactDate: data.note.shouldUpdateLastContactDate,
                    },
                };
            },
        }),
        getPatientTimeLine: builder.query<ApiResponse, string>({
            query: (patimentId) => ({
                url: `/timeline/${patimentId}`,
                method: 'GET',
            }),
        }),
        getPatientChallengeTimeLine: builder.query<ApiResponse, IChallengeTimelineRequestParams>({
            query: (req) => ({
                url: `/timeline/challenges?patientId=${req.patientId}&challengeId=${req.challengeId}`,
                method: 'GET',
            }),
        }),
        savePatientResources: builder.mutation<ApiResponse, IPatientResourceRequest>({
            query: (data) => {
                const formData = new FormData();
                formData.append('file', data.file);
                formData.append('resourceName', data.resourceName);
                data?.resourceDocName && formData.append('resourceDocName', data.resourceDocName);
                data?.patientId && formData.append('patientId', data?.patientId);
                return {
                    url: `patient-resources`,
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        editPatientResources: builder.mutation<ApiResponse, IPatientResourceRequest>({
            query: (data) => {
                const formData = new FormData();
                formData.append('file', data.file);
                return {
                    url: `patient-resources/${data.resourceId}`,
                    method: 'PUT',
                    body: formData,
                };
            },
        }),
        saveServeyNotes: builder.mutation<ApiResponse, IServeyNoteRequest>({
            query: (data) => ({
                url: '/notes',
                method: 'POST',
                body: {
                    ...data,
                    note: data.note.note,
                    shouldUpdateLastContactDate: data.note.shouldUpdateLastContactDate,
                },
            }),
        }),
        updateServeyNotes: builder.mutation<ApiResponse, IServeyNoteRequest>({
            query: (data) => ({
                url: `/surveys/${data.survey}`,
                method: 'PUT',
                body: {
                    ...data,
                    note: data.note.note,
                },
            }),
        }),
        updateNotes: builder.mutation<ApiResponse, IServeyNoteRequest>({
            query: (data) => ({
                url: `/notes/${data.note._id}`,
                method: 'PUT',
                body: {
                    ...data,
                    note: data.note.note,
                    shouldUpdateLastContactDate: data.note.shouldUpdateLastContactDate,
                },
            }),
        }),
        toggleTodoCheckbox: builder.mutation<ApiResponse, ITodoToggleRequestParams>({
            query: (data) => ({
                url: `/notes/toggle-todo/${data.noteId}`,
                method: 'PUT',
                body: {
                    ...data,
                    toggleOptions: data.toggleOptions,
                    actionedUser: data.actionedUser,
                    patient: data.patient,
                },
            }),
        }),
        getNoteHistory: builder.query<ApiResponse, string>({
            query: (note_id) => ({
                url: `notes/${note_id}/history`,
                method: 'GET',
            }),
        }),
        // resourceInfo: builder.mutation<ApiResponse, IImageCreateRequest>({
        //     query: (data) => ({
        //         url: '/files',
        //         method: 'POST',
        //         body: data,
        //         onprogress: (progressEvent: ProgressEvent) => {
        //         },
        //     }),
        // }),
        resourceInfo: builder.mutation<ApiResponse, IImageCreateRequest>({
            queryFn: async (data) => {
                try {
                    const result = await axiosBase.post('/files', data, {
                        onUploadProgress: (upload: AxiosProgressEvent) => {
                            // let uploadloadProgress = Math.round((100 * upload.loaded) / upload?.total);
                            if (data.uploadProgressCallback) {
                                data.uploadProgressCallback(upload);
                            }
                        },
                    });
                    return { data: result.data };
                } catch (axiosError: any) {
                    let err = axiosError;
                    return {
                        error: {
                            status: err?.response?.status,
                            data: err?.response?.data || err?.message,
                        },
                    };
                }
            },
        }),
        resourceInfoForm: builder.mutation<ApiResponse, IExistingResourceRequest>({
            query: (data) => ({
                url: `/patients/release-of-information/${data.patientId}/from-resources`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Patient'],
        }),
        deleteResourceInfo: builder.mutation<ApiResponse, string>({
            query: (fileId) => ({
                url: `/files/${fileId}`,
                method: 'DELETE',
                body: { type: 'release-of-info' },
            }),
        }),
    }),
});

export const {
    usePatientAddMutation,
    usePatientEditMutation,
    usePatientEditAssignMutation,
    usePatientDeleteMutation,
    usePatientGetQuery,
    usePatientListQuery,
    usePatientEditAssignBulkMutation,
    useLazyPatientChallengeQuery,
    useMakeUrgentMutation,
    useSaveNotesMutation,
    useLazyGetSolutionDataQuery,
    useGetResourcesQuery,
    useSaveSolutionNoteMutation,
    useSavePatientResourcesMutation,
    useEditPatientResourcesMutation,
    useDownloadResourcesQuery,
    useGetPatientTimeLineQuery,
    useGetPatientChallengeTimeLineQuery,
    useSaveServeyNotesMutation,
    useUpdateServeyNotesMutation,
    useUpdateNotesMutation,
    useToggleTodoCheckboxMutation,
    useLazyGetNoteHistoryQuery,
    useResourceInfoMutation,
    useDeleteResourceInfoMutation,
    useLazyGetReleaseInfoFilesQuery,
    useResourceInfoFormMutation,
} = patientAPI;
