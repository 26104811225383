import { createApi } from '@reduxjs/toolkit/query/react';
import {
    ApiResponse,
    AuthApiRequest,
    IAuthNewPasswordRequest,
    IAuthRequest,
    IAuthUserDetailsRequest,
    IAuthVerifyRequest,
    MfaVerifyApiRequest,
} from '../utils/types/api.type';
import { baseQueryWithReAuth } from './interceptors';

const baseQuery = baseQueryWithReAuth;

export const authAPI = createApi({
    reducerPath: '/authApi',
    baseQuery,
    endpoints: (builder) => ({
        signUp: builder.mutation<ApiResponse, AuthApiRequest>({
            query: (data) => ({
                url: '/auth/signup',
                method: 'POST',
                body: data,
            }),
        }),
        signIn: builder.mutation<ApiResponse, AuthApiRequest>({
            query: (data) => ({
                url: '/auth/login',
                method: 'POST',
                body: data,
            }),
        }),
        verifyMfa: builder.mutation<ApiResponse, MfaVerifyApiRequest>({
            query: (data) => ({
                url: '/auth/verify-mfa',
                method: 'POST',
                body: data,
            }),
        }),
        signOut: builder.mutation<ApiResponse, IAuthRequest>({
            query: (data) => ({
                url: '/auth/signout',
                method: 'POST',
                body: data,
            }),
        }),
        refresh: builder.mutation<ApiResponse, IAuthRequest>({
            query: (data) => ({
                url: '/auth/refresh',
                method: 'POST',
                body: data,
            }),
        }),
        verify: builder.mutation<ApiResponse, IAuthVerifyRequest>({
            query: (data) => ({
                url: '/auth/verify',
                method: 'POST',
                body: data,
            }),
        }),
        resendCode: builder.mutation<ApiResponse, IAuthRequest>({
            query: (data) => ({
                url: '/auth/resend',
                method: 'POST',
                body: data,
            }),
        }),
        userDetails: builder.mutation<ApiResponse, IAuthUserDetailsRequest>({
            query: (data) => ({
                url: '/user',
                method: 'PUT',
                body: data,
            }),
        }),
        resetPassword: builder.mutation<ApiResponse, IAuthRequest>({
            query: (data) => ({
                url: '/auth/forgotpassword',
                method: 'POST',
                body: data,
            }),
        }),
        newPassword: builder.mutation<ApiResponse, IAuthNewPasswordRequest>({
            query: (data) => ({
                url: '/auth/confirmforgotpassword',
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useSignUpMutation,
    useSignInMutation,
    useVerifyMfaMutation,
    useVerifyMutation,
    useResendCodeMutation,
    useUserDetailsMutation,
    useResetPasswordMutation,
    useNewPasswordMutation,
    useSignOutMutation,
    useRefreshMutation,
} = authAPI;
