import { IFormRow, ILetterSchema, IQuestionSchema, ISchema, ISchemaStep, isComponentVisible } from 'dfh-form-generator';
import { isEqual } from 'lodash';
import { ScreenerStatus } from 'utils/enum';
import { v4 as uuidv4 } from 'uuid';

export const getProgress = (patientSchema: ISchema | undefined): number => {
    let totalQ: number = 0;
    let answeredQ: number = 0;
    if (patientSchema && patientSchema.schemaSteps) {
        const values = getValues(patientSchema.schemaSteps);
        patientSchema.schemaSteps.forEach((step: ISchemaStep) => {
            const updatedStepArray = step.formRows.flatMap((values: IFormRow) =>
                values.questionList.map((items: IQuestionSchema) => items),
            );
            updatedStepArray
                .filter((vals: IQuestionSchema) => !vals.visibleIf || isComponentVisible(vals.visibleIf, values))
                .forEach((item: IQuestionSchema) => {
                    totalQ = totalQ + 1;
                    if (
                        item.question.selected && // Check if item.field.selected exists
                        !isEqual(item.question.selected, {}) &&
                        item.question.selected.value !== '' && // Check if item.field.selected.value is not empty
                        !isEqual(item.question.selected.value, '')
                    ) {
                        answeredQ = answeredQ + 1;
                    }
                });
        });
    }
    return Math.round((answeredQ / totalQ) * 100);
};

const getValues = (schemas: ISchemaStep[]): {} => {
    let values = {};
    schemas.map((schema) => {
        schema.formRows.map((row) => {
            const fieldValue = row.questionList.reduce((obj, item) => {
                return {
                    ...obj,
                    [item.question._id]: item.question.selected ? item.question.selected : { value: '' },
                };
            }, {});
            values = { ...values, ...fieldValue };
        });
    });

    return values;
};

export const getNewScreenerState = (
    patientId: string | undefined,
    schema: ISchema,
    status: ScreenerStatus,
    screenerData: {
        patientId: string;
        schema: ISchema;
        status: ScreenerStatus;
    }[],
) => {
    const newScreenerData: { patientId: string; schema: ISchema; status: ScreenerStatus }[] = [];
    let isExist: boolean = false;
    screenerData.map((data) => {
        if (data.patientId === patientId && patientId) {
            newScreenerData.push({ patientId: patientId, schema: schema, status: status });
            isExist = true;
        } else {
            newScreenerData.push(data);
        }
    });
    if (!isExist && patientId) newScreenerData.push({ patientId: patientId, schema: schema, status: status });
    return newScreenerData;
};

export const getNewSchemaWithPrevData = (newSchema: ISchema, prevSchema: ISchema) => {
    if (!prevSchema) return newSchema;

    const updatedSchema = { ...newSchema }; // Shallow copy of newSchema

    updatedSchema.schemaSteps = updatedSchema.schemaSteps && updatedSchema.schemaSteps.length && updatedSchema.schemaSteps.map((step) => {
        return {
            ...step,
            formRows: step.formRows?.map((row) => {
                return {
                    ...row,
                    questionList: row.questionList.map((fieldData) => {
                        const correspondingField = prevSchema.schemaSteps
                            .flatMap((prevStep) => prevStep.formRows)
                            .flatMap((prevRow) => prevRow.questionList)
                            .find((prevFieldData) => isEqual(prevFieldData.question._id, fieldData.question._id));

                        if (
                            correspondingField &&
                            correspondingField.question.answerPrevData &&
                            correspondingField.question.answerPrevData.answerLabel
                        ) {
                            // Create a new field object with updated answerPrevData
                            return {
                                ...fieldData,
                                question: {
                                    ...fieldData.question,
                                    answerPrevData: correspondingField.question.answerPrevData,
                                },
                            };
                        }
                        // If no matching field found, return original fieldData
                        return fieldData;
                    }),
                };
            }),
        };
    }) || [];
    return updatedSchema;
};

export const getLetterSchemaObject = (newSchema: ILetterSchema, _patientId: string) => {
    if (newSchema) {
        const schema = { ...newSchema, _id: uuidv4() }; // Assign a new random ID to the top-level schema

        schema.formRows = schema?.formRows.map((row) => {
            const newRow = { ...row, _id: uuidv4() }; // Assign a new random ID to each row and create a copy of the row

            newRow.fieldList = newRow?.fieldList.map((field) => {
                let newField = { ...field, _id: uuidv4() };
                if (field?.fieldType === 'checkbox') {
                    const isChecked = field?.selected?.isChecked; // Assuming `isChecked` is a property of `selected`
                    newField = {
                        ...newField,
                        selected: {
                            ...newField?.selected,
                            isChecked: isChecked === undefined || isChecked === false ? false : true,
                        },
                    };
                }
                return newField;
            });

            return newRow;
        });
        return schema;
    } else {
        return null;
    }
};
