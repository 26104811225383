import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import authReducer from './authSlice';
import userReducer from './userSlice';
import patientReducer from './patientSlice';
import reportingReducer from './reportingSlice';
import { authAPI } from '../api/authAPI';
import { homeAPI } from '../api/homeAPI';
import { userAPI } from '../api/userAPI';
import { imageAPI } from '../api/imageAPI';
import { patientAPI } from '../api/patientAPI';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { screeningAPI } from 'api/screeningAPI';
import schemaSlice from './schemaSlice';
import screenerSlice from './screenerSlice';
import { globalsAPI } from 'api/globalsAPI';
import letterFormSlice from './letterFormSlice';
import letterReducer from './letterSlice';
import { reportingAPI } from 'api/reportingAPI';
import pdfUrlReducer from "./pdfSlice";

const reducers = combineReducers({
    [globalsAPI.reducerPath]: globalsAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [homeAPI.reducerPath]: homeAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [imageAPI.reducerPath]: imageAPI.reducer,
    [patientAPI.reducerPath]: patientAPI.reducer,
    [reportingAPI.reducerPath]: reportingAPI.reducer,
    [screeningAPI.reducerPath]: screeningAPI.reducer,
    app: appReducer,
    auth: authReducer,
    patient: patientReducer,
    reporting: reportingReducer,
    user: userReducer,
    schema: schemaSlice,
    screener: screenerSlice,
    letterSchema: letterFormSlice,
    letter: letterReducer,
    pdf: pdfUrlReducer
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
        }).concat(
            globalsAPI.middleware,
            authAPI.middleware,
            homeAPI.middleware,
            userAPI.middleware,
            imageAPI.middleware,
            patientAPI.middleware,
            reportingAPI.middleware,
            screeningAPI.middleware,
        );
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
