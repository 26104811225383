import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { IComponent, ILetterSchema, ISelected, LetterGenerator } from 'dfh-form-generator';
import { Breadcrumb, Button, ComponentProvider, Row, Typhography } from 'dfh-ui-library';
import { ILetterProps } from 'utils/types/patient.type';
import { letterComponentSchema } from '../helpers/default';
import moment from 'moment';
import { ILetterRequest } from 'utils/types/screener.type';
import PdfViewer from './PdfViewer';
import _ from 'lodash';
import { downloadDocument } from '../helpers/downloadHelper';
import { useDispatch } from 'react-redux';
import { setPdf } from 'stores/pdfSlice';

const LetterComponent = (props: ILetterProps, _ref: React.Ref<unknown> | undefined) => {
    const [components, setComponents] = React.useState<IComponent[]>();
    const [newSchema, setNewSchema] = React.useState<ILetterSchema>();
    const [pdfUrl, setPdfUrl] = useState('');
    const [enablePDFView, setEnablePDFView] = useState<boolean>(false);
    const [newValueChange, setNewValueChange] = useState<{ [key: string]: any }>({});
    const [fileId, setFileId] = useState<string>('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (!newSchema) {
            props.schema && setNewSchema(props.schema);
        }
    }, []);

    // useEffect(() => {
    //     if (Object.keys(newValueChange).length === 1) {
    //         if (newSchema) {
    //             const updatedSchema: ILetterSchema = {
    //                 ...newSchema,
    //                 formRows: newSchema.formRows.map((row) => ({
    //                     ...row,
    //                     fieldList: row.fieldList.map((field) => {
    //                         console.info(field._id);
    //                         if (newValueChange.hasOwnProperty(field._id)) {
    //                             // Determine the value based on the field type
                                
    //                             const newValue =
    //                                 field.fieldType === 'Date'
    //                                     ? moment(newValueChange[field._id]).isValid()
    //                                         ? moment(newValueChange[field._id]).format('MM-DD-YYYY')
    //                                         : undefined
    //                                     : newValueChange[field._id];

    //                             // Ensure the selected object is set correctly without nesting
    //                             if (field.fieldType === 'Date' || field.fieldType === 'Signature') {
    //                                 const updatedSelected: ISelected = {
    //                                     value: newValue,
    //                                 };
    //                                 // Return the updated field
    //                                 return {
    //                                     ...field,
    //                                     selected: updatedSelected,
    //                                 };
    //                             } else {
    //                                 const updatedSelected: ISelected = newValue;

    //                                 // Return the updated field
    //                                 return {
    //                                     ...field,
    //                                     selected: updatedSelected,
    //                                 };
    //                             }
    //                         }
    //                         return field;
    //                     }),
    //                 })),
    //             };
    //             if (!_.isEqual(newSchema, updatedSchema)) {
    //                 setNewSchema(updatedSchema);
    //             }
    //         }
    //     }
    // }, [newValueChange]);

    useEffect(() => {
        if (Object.keys(newValueChange).length === 1) {
            if (newSchema) {
                const updatedSchema: ILetterSchema = {
                    ...newSchema,
                    formRows: newSchema.formRows.map((row) => ({
                        ...row,
                        fieldList: row.fieldList.map((field) => {
                            if (newValueChange.hasOwnProperty(field._id)) {
                                // Add proper date validation and handling
                                let newValue = newValueChange[field._id];
                                
                                if (field.fieldType === 'Date') {
                                    // Handle date values
                                    const momentDate = moment(newValue);
                                    if (momentDate.isValid()) {
                                        newValue = momentDate.format('MM-DD-YYYY');
                                    } else {
                                        // Provide a default date or null instead of undefined
                                        newValue = null;
                                    }
                                }
    
                                // Create the selected object based on field type
                                const updatedSelected: ISelected = 
                                    field.fieldType === 'Date' || field.fieldType === 'Signature'
                                        ? { value: newValue }
                                        : newValue;
    
                                return {
                                    ...field,
                                    selected: updatedSelected,
                                };
                            }
                            return field;
                        }),
                    })),
                };
                
                if (!_.isEqual(newSchema, updatedSchema)) {
                    setNewSchema(updatedSchema);
                }
            }
        }
    }, [newValueChange]);

    const handleChangesProps = (changedValues: { [key: string]: any }) => {
        setNewValueChange(changedValues);
    };

    const getLetterDataObj = (isSave: boolean = false, fileId: string = '') => {
        const letterRequest: ILetterRequest = {
            varientId: props.receivedData.varientId,
            configJSON: {
                form: undefined,
            },
            patientChallengeId: props.receivedData.challengeId,
            patientId: props.receivedData.id,
            solutionId: props.receivedData.solutionId,
            tempChallageId: props.receivedData.tempChallageId,
            isSave: isSave,
            fileId: fileId,
        };
        return letterRequest;
    };

    const previewLetter = async () => {
        const letterRequest = {
            ...getLetterDataObj(),
            configJSON: {
                form: newSchema,
            },
        };
        if (props.isReleaseInfo) {
            const response =
                (await newSchema) &&
                props.updateLetterSchemaValuesForRInfo &&
                props.updateLetterSchemaValuesForRInfo(letterRequest);
            if (response) {
                response.then((result) => {
                    setFileId(result?.data.data.fileId);
                    setPdfUrl(result?.data.data.signedUrl);
                    dispatch(setPdf(result?.data.data.signedUrl));
                    setEnablePDFView(true);
                });
            }
        } else {
            const response =
                (await newSchema) && props.updateLetterSchemaValues && props.updateLetterSchemaValues(letterRequest);
            if (response) {
                response.then((result) => {
                    if (result?.error) {
                        console.error(result.error?.data, "Error While pdf gen")
                        return;
                    }
                    setPdfUrl(result?.data.data.signedUrl);
                    dispatch(setPdf(result?.data.data.signedUrl));
                    setEnablePDFView(true);
                });
            }
        }
    };

    const breadcrumbItems = useMemo(() => (
        props?.schema && props.isReleaseInfo
            ? [
                {
                    label: 'Patients',
                    link: '/',
                    navAction: () => props.backToView && props.backToView(getLetterDataObj(false))
                },
                {
                    label: props.receivedData.name,
                },
            ]
            : [
                {
                    label: 'Patients',
                    link: '/',
                },
                {
                    label: props.receivedData.name,
                    link: '/',
                },
                {
                    label: 'Solutions',
                    link: '/',
                },
                {
                    label: `${props?.schema?.title} (${props?.varient})`,
                },
            ]), []
    );
    return (
        <Typhography component='div'>
            {props?.schema && (
                <>
                    <Row additionalClasses='relative p-4 justify-center'>
                        <Breadcrumb items={breadcrumbItems} singleArrow={true} />
                    </Row>
                    <Typhography component='div' additionalClasses='border mx-20  rounded-lg'>
                        <Typhography component='div' additionalClasses='p-20 h-[calc(100vh-230px)] overflow-y-auto'>
                            {enablePDFView && pdfUrl ? (
                                <PdfViewer fileUrl={pdfUrl} />
                            ) : (
                                <ComponentProvider schema={letterComponentSchema} setComponents={setComponents}>
                    
                    
                                    {components && newSchema && (
                                        <LetterGenerator
                                            schema={newSchema}
                                            components={components}
                                            currentStepNumber={1}
                                            handleChanges={handleChangesProps}
                                            viewMode={false}
                                        />
                                    )}
                                </ComponentProvider>
                            )}
                        </Typhography>

                        <Typhography
                            component='div'
                            additionalClasses='flex flex-row w-full border-t justify-between border-gray-300 place-items-center py-4 px-6 bg-white fixed bottom-0 sticky'
                        >
                            <>
                                {' '}
                                {enablePDFView ? (
                                    <>
                                        <Typhography component='div' additionalClasses='flex'>
                                            <Button
                                                buttonLabel='Back'
                                                variants='primaryWhite'
                                                onClick={() => setEnablePDFView(false)}
                                                isIconEnabled={true}
                                                iconAlignment={'left'}
                                                iconType='BlackPrevIcon'
                                            />
                                        </Typhography>
                                        <Typhography component='div' additionalClasses='flex gap-2'>
                                            <Button
                                                buttonLabel='Download'
                                                variants='primaryWhite'
                                                onClick={() => pdfUrl && downloadDocument(pdfUrl)}
                                                isIconEnabled={true}
                                                iconAlignment={'left'}
                                                iconType='Download'
                                                iconColor='#000000'
                                            />
                                            <Button
                                                buttonLabel='Print'
                                                variants='primaryWhite'
                                                onClick={() => pdfUrl && downloadDocument(pdfUrl)}
                                                isIconEnabled={true}
                                                iconAlignment={'left'}
                                                iconType='PrinterIconMedium'
                                                iconColor='#000000'
                                            />
                                        </Typhography>

                                        <Typhography component='div' additionalClasses='flex items-center gap-2'>
                                            <Button
                                                buttonLabel='Save & Exit'
                                                variants='primaryWhite'
                                                onClick={() =>
                                                    props.backToView && props.backToView(getLetterDataObj(true, fileId))
                                                }
                                                isIconEnabled={false}
                                                iconAlignment={'left'}
                                                iconType='BlackPrevIcon'
                                            />
                                        </Typhography>
                                    </>
                                ) : (
                                    <>
                                        <Typhography component='div' additionalClasses='flex justify-self-start'>
                                            <Button
                                                buttonLabel='Back'
                                                variants='primaryWhite'
                                                onClick={() =>
                                                    props.backToView && props.backToView(getLetterDataObj(false))
                                                }
                                                isIconEnabled={true}
                                                iconAlignment={'left'}
                                                iconType='BlackPrevIcon'
                                            />
                                        </Typhography>

                                        <Typhography
                                            component='div'
                                            additionalClasses='flex items-center gap-2 justify-self-end'
                                        >
                                            <Button
                                                buttonLabel={'Preview'}
                                                variants='primaryBlue'
                                                onClick={previewLetter}
                                                iconAlignment={'right'}
                                                iconType='WhiteNextIcon'
                                                iconClass='mt-1 ml-2'
                                                isIconEnabled={true}
                                            />
                                        </Typhography>
                                    </>
                                )}
                            </>
                        </Typhography>
                    </Typhography>
                </>
            )}
        </Typhography>
    );
};

export default forwardRef(LetterComponent);
