import { Button, ButtonGroupWithInputs, Heading, Icon, Modal, Typhography } from 'dfh-ui-library'
import moment from 'moment';
import { useMemo, useState } from 'react';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { PatientConsent } from 'utils/types/patient.type';

interface PatientConsentModalProps {
    isOpen: boolean;
    handleCancelButton?: () => void;
    handleSaveConsent: (values: PatientConsent) => void;

}

function PatientConsentModal(consentProps: PatientConsentModalProps) {
    const { isOpen, handleCancelButton } = consentProps;
    const [acceptedValue, setAcceptedValues] = useState({
        isUnderstood: false,
        isAskedQuestions: false,
        isQuestionsAnswered: false,
        isConsentGiven: false,
    });
    const user = LocalStorageService().getUser();
    const fullName = user?.fullName;

    const handleAcceptance = (key: keyof typeof acceptedValue) => {
        setAcceptedValues({
            ...acceptedValue,
            [key]: acceptedValue[key] === true ? false : true,
        });
    }

    const isSaveEnabled = useMemo(() => {
        return Object.values(acceptedValue).every(value => value === true);
    }, [acceptedValue]);

    const onSaveConsent = () => {
        const patientConsent: PatientConsent = {
            consentStatus: 'CONSENT GIVEN',
            consentDate: moment(new Date().toISOString()).format('MM/DD/YYYY'),
            consentBy: fullName,
        }
        consentProps.handleSaveConsent(patientConsent);
    }


    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'w-[80vw] h-[calc(100vh-25px)] flex flex-col'}
        >
            {/* Fixed Header */}
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4 flex-shrink-0'>
                <Typhography component='div'>
                    <Heading
                        type='h2'
                        colorType='dark'
                        label="Patient Consent"
                        additionalClasses='mb-4'
                    />
                </Typhography>
                <Typhography component='div'>
                    <Icon iconType='closeModal' additionalClasses='' onClick={handleCancelButton} />
                </Typhography>
            </Typhography>

            {/* Scrollable Body */}
            <Typhography component='div' additionalClasses='border border-x-0 p-6 flex-grow overflow-y-auto'>
                <Typhography component='p' additionalClasses='mb-4 text-[14px]'>
                    By consenting, you agree to participate and share information with in a network of healthcare and social
                    service organizations directly involved in your care.
                    Your personal information may be shared securely in this network
                    in accordance with all applicable laws.
                </Typhography>
                <Typhography component='p' additionalClasses='mb-4 text-[14px]'>
                    This consent covers all information shared by you or
                    anyone who has the right to share information on your behalf.
                    At any point, you may restrict your information or request to have it
                    removed at any time by contacting us at <a className='decoration-none font-semibold  ' href='mailto:privacy@docsforhealth.org'>privacy@docsforhealth.org</a> .
                </Typhography>
                <Typhography component='p' additionalClasses='mb-4 text-[14px]'>
                    For more information about our privacy practices, you may reach out to us at
                    <a className='decoration-none font-semibold' href='mailto:privacy@docsforhealth.org'>&nbsp;privacy@docsforhealth.org&nbsp;</a>
                    or by viewing our Privacy Policy at
                    <a className='decoration-none font-semibold' target='_blank' href='https://docsforhealth.org/privacy-policy'>&nbsp;docsforhealth.org/privacy-policy</a>.
                </Typhography>



                {/* Form  fields*/}
                <Typhography component='div' additionalClasses='grid grid-cols-1 gap-6 pt-6'>
                    <Typhography component='div' additionalClasses='col-span-1'>
                        <Typhography component='p' additionalClasses='mb-1 font-medium text-[14px]'>
                            Do you understand and agree with what you have just read?
                        </Typhography>
                        <ButtonGroupWithInputs
                            options={[{ id: 1, value: 'Yes' }]}
                            label=' '
                            onSelect={(_selectedOption: any) => { handleAcceptance('isUnderstood') }}
                            selected={acceptedValue.isUnderstood === true ? 1 : -1}

                            orderBy='button'

                            buttonGroupCol='grid-cols-1 '
                            additionalClasses='pt-1'
                        />
                    </Typhography>

                    <Typhography component='div' additionalClasses='col-span-1'>
                        <Typhography component='p' additionalClasses='mb-1 font-medium text-[14px]'>
                            Did I give you a chance to ask any questions that you have?
                        </Typhography>
                        <ButtonGroupWithInputs
                            options={[{ id: 1, value: 'Yes' }]}
                            label=' '
                            onSelect={(_selectedOption: any) => { handleAcceptance('isAskedQuestions') }}
                            selected={acceptedValue.isAskedQuestions === true ? 1 : -1}
                            orderBy='button'

                            buttonGroupCol='grid-cols-1 '
                            additionalClasses='pt-1'
                        />
                    </Typhography>

                    <Typhography component='div' additionalClasses='col-span-1 '>
                        <Typhography component='p' additionalClasses='mb-1 font-medium text-[14px]'>
                            Do you have any other questions?
                        </Typhography>
                        <ButtonGroupWithInputs
                            options={[{ id: 1, value: 'Yes' }]}
                            label=' '
                            onSelect={(_selectedOption: any) => { handleAcceptance('isQuestionsAnswered') }}
                            selected={acceptedValue.isQuestionsAnswered === true ? 1 : -1}

                            orderBy='button'

                            buttonGroupCol='grid-cols-1 '
                            additionalClasses='pt-1'
                        />
                    </Typhography>

                    <Typhography component='div' additionalClasses='col-span-1'>
                        <Typhography component='p' additionalClasses='mb-1 font-medium text-[14px]'>
                            Do you provide your consent?
                        </Typhography>
                        <ButtonGroupWithInputs
                            options={[{ id: 1, value: 'Yes' }]}
                            label=' '
                            onSelect={(_selectedOption: any) => { handleAcceptance('isConsentGiven') }}
                            selected={acceptedValue.isConsentGiven === true ? 1 : -1}
                            orderBy='button'

                            buttonGroupCol='grid-cols-1 '
                            additionalClasses='pt-1'
                        />
                    </Typhography>

                    <Typhography additionalClasses='border border-x-0' />

                    <Typhography component='div' additionalClasses='col-span-1'>
                        <Typhography component='p' additionalClasses='mb-4 text-[14px]'>
                            By saving this consent, I, <b>{fullName}</b>, attest that this patient or their legal representative was:
                        </Typhography>

                        <Typhography component='div' additionalClasses='flex flex-col gap-2 pl-3'>
                            <Typhography component='div' additionalClasses='flex items-start gap-2'>
                                <Typhography component='span' additionalClasses='text-[14px]'>1.</Typhography>
                                <Typhography component='span' additionalClasses='text-[14px]'>Read the consent,</Typhography>
                            </Typhography>

                            <Typhography component='div' additionalClasses='flex items-start gap-2'>
                                <Typhography component='span' additionalClasses='text-[14px]'>2.</Typhography>
                                <Typhography component='span' additionalClasses='text-[14px]'>Given an opportunity ask questions, and</Typhography>
                            </Typhography>

                            <Typhography component='div' additionalClasses='flex items-start gap-2'>
                                <Typhography component='span' additionalClasses='text-[14px]'>3.</Typhography>
                                <Typhography component='span' additionalClasses='text-[14px]'>Have these questions answered to their satisfaction</Typhography>
                            </Typhography>
                        </Typhography>


                        <Typhography component='p' additionalClasses='my-4 text-[14px]'>
                            I, <b>{fullName}</b>, affirm that this patient or their legal representative provided consent for their participation in the Docs for Health platform.
                        </Typhography>
                    </Typhography>

                </Typhography>
            </Typhography>

            {/* Fixed Footer */}
            <Typhography component='div' additionalClasses='flex justify-between flex-row gap-4 mt-4 pb-4 px-4 flex-shrink-0'>
                <Typhography component='div'>
                    <Button
                        buttonLabel='Cancel'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={handleCancelButton}
                    />
                </Typhography>
                <Typhography component='div'>
                    <Button
                        buttonLabel='Save consent'
                        data-testId='InputField-id'
                        variants='primaryBlue'
                        onClick={onSaveConsent}
                        isDisabled={!isSaveEnabled}
                    />
                </Typhography>
            </Typhography>

        </Modal>
    )
}

export default PatientConsentModal
