import { toast } from 'react-toastify';
import { themeColors } from './constants/themeColors';
import moment from 'moment';

export const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * themeColors.length);
    return themeColors[randomIndex];
}

export const shortContent = (content: string):string => {
    return  content.length > 50 ? `${content.substring(0,50)}...` : content;
}

export const showToast = (message: string, type: 'success' | 'error' | 'info' | 'warning') => {
    toast(message, {
        type: type,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}
export const formatDate = (dateString: string | Date | undefined): string => {
    if (!dateString) return '';
    
    const momentDate = moment(dateString);
    if (!momentDate.isValid()) return '';
    
    return momentDate.format('MM/DD/YYYY');
};
