import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import config from '../configs/config';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { ApiTokenResponse, IAuthRequest } from 'utils/types/api.type';
import axios, { AxiosHeaders } from 'axios';
// import AbortControllerManager from '../repositories/AbortControllerManager';

const localStorageService = LocalStorageService();
let refreshTokenPromise: Promise<any> | null = null;


// Initialize the AbortControllerManager
// const abortControllerManager = AbortControllerManager.getInstance();

export const baseQuery = fetchBaseQuery({
    baseUrl: config.api.baseUrl,
    prepareHeaders(headers) {
        const authorization = localStorageService.getAccessToken();
        const idToken = localStorageService.getIdToken();
        const refreshToken = localStorageService.getRefreshToken();
        if (authorization) {
            headers.set('authorization', `Bearer ${authorization}`);
        }
        if (idToken) {
            headers.set('id-token', `${idToken}`);
        }
        if (refreshToken) {
            headers.set('refresh-token', `${refreshToken}`);
        }
        return headers;
    },
    // fetchFn: (input, init) => {
    //     const abortSignal = abortControllerManager.getAbortSignal();
    //     if (abortSignal) {
    //         init = { ...init, signal: abortSignal };
    //     }
    //     return fetch(input, init);
    // },
});

export const baseQueryWithReAuth: BaseQueryFn<any | FetchArgs, any, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    // const abortController = abortControllerManager.initializeAbortController();
    let result = await baseQuery( {
        ...args,
        // signal: abortController.signal, // Attach the AbortSignal
    }, api, extraOptions);

    if (result.error && result.error.status === 401) {
        if (!refreshTokenPromise) {
            const user = localStorageService.getUser();
            const userData: IAuthRequest = { email: user?.email };

            refreshTokenPromise = (async () => {
                try {
                    const refreshResult = await baseQuery(
                        {
                            url: 'auth/refresh',
                            method: 'POST',
                            body: userData,
                        },
                        api,
                        extraOptions,
                    );

                    if (refreshResult && refreshResult.data) {
                        const tokens = refreshResult.data as ApiTokenResponse;
                        // Store the new token
                        localStorageService.setAuthTokens({
                            accessToken: tokens.data.accessToken,
                            refreshToken: tokens.data.refreshToken,
                            idToken: tokens.data.idToken,
                            user: user,
                        });
                        return tokens.data.accessToken;
                    } else {
                        localStorageService.clearAuthTokens();
                        window.location.href = '/login';
                        throw new Error('Failed to refresh token');
                    }
                } catch (error) {
                    console.error('An error occurred during refresh:', error);
                    localStorageService.clearAuthTokens();
                    throw error;
                } finally {
                    refreshTokenPromise = null; // Reset the promise after the request completes
                }
            })();
        }

        try {
            const newAccessToken = await refreshTokenPromise;
            // Retry the initial query with the new access token
            if (newAccessToken) {
                result = await baseQuery({...args, 
                    // signal: abortController.signal
                }, api, extraOptions);
            }
        } catch (error) {
            // Handle the error if the refresh token request fails
            console.error('An error occurred during refresh token handling:', error);
        }
    }

    return result;
};

const prepareAxiosHeaders = (headers: AxiosHeaders, refreshedToken?: { accessToken: string; refreshToken: string; idToken: string; user: any;}
    //  ,abortController?: AbortController
    ) => {
    const authorization = refreshedToken?.accessToken || localStorageService.getAccessToken();
    const idToken = refreshedToken?.idToken || localStorageService.getIdToken();
    const refreshToken = refreshedToken?.refreshToken || localStorageService.getRefreshToken();
    if (authorization) {
        headers.set('Authorization', `Bearer ${authorization}`);
    }
    if (idToken) {
        headers.set('Id-Token', `${idToken}`);
    }
    if (refreshToken) {
        headers.set('Refresh-Token', `${refreshToken}`);
    }
    // if(abortController){
    //     headers.set('Abort-Signal', `${abortController.signal}`);
    // }
    return headers;
};


export const axiosBase = axios.create({
    baseURL: config.api.baseUrl,
    headers: prepareAxiosHeaders(new AxiosHeaders()),
});
axiosBase.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response 
            // && error.response.status === 401
        ) {
            // Refresh tocken
            if (!refreshTokenPromise) {
                const user = localStorageService.getUser();
                const userData: IAuthRequest = { email: user?.email };

                refreshTokenPromise = (async () => {
                    try {
                        const refreshResult = await axiosBase.post('auth/refresh',userData);

                        if (refreshResult && refreshResult.data) {
                            const tokens = refreshResult.data as ApiTokenResponse;
                            // Store the new token
                            localStorageService.setAuthTokens({
                                accessToken: tokens.data.accessToken,
                                refreshToken: tokens.data.refreshToken,
                                idToken: tokens.data.idToken,
                                user: user,
                            });
                            return tokens.data.accessToken;
                        } else {
                            localStorageService.clearAuthTokens();
                            throw new Error('Failed to refresh token');
                        }
                    } catch (error) {
                        console.error('An error occurred during refresh:', error);
                        localStorageService.clearAuthTokens();
                        throw error;
                    } 
                    // finally {
                    //     refreshTokenPromise = null; // Reset the promise after the request completes
                    // }
                })();
            }

            const originalRequest = error.config;
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                try {
                    const newTokens = await refreshTokenPromise;
                    if (newTokens) {
                        originalRequest.headers = prepareAxiosHeaders(new AxiosHeaders(), newTokens);
                        // originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosBase(originalRequest);
                    }
                } catch (error) {
                    console.error('An error occurred during refresh token handling:', error);
                }
            }
        }
        return Promise.reject(error);
    },
);
