import { Heading, Icon, Modal, Typhography } from 'dfh-ui-library';
import { useState } from 'react';
import _ from 'lodash';
import { UrlPreview } from './imagePreview';

interface FormUploadModalProps {
    isOpenModal: boolean;
    handleCancelButton?: () => void;
    signedUrl?: string;
    title?: string;
}
export const PdfPreviewModal = ({ title = 'Letter Preview', ...formProps }: FormUploadModalProps) => {
    const [isOpen, _setIsOpen] = useState(formProps.isOpenModal);
    const { signedUrl, handleCancelButton } = formProps;
    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'w-[800px] max-h-screen  items-center justify-center flex-col'}
        >
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4'>
                <Typhography component='div'>
                    <Heading type='h2' colorType='dark' label={title} additionalClasses='mb-4' />
                </Typhography>
                <Typhography component='div'>
                    <Icon iconType='closeModal' additionalClasses='' onClick={handleCancelButton} />
                </Typhography>
            </Typhography>
            <Typhography component='div' additionalClasses='border border-x-0'>
                <UrlPreview content={signedUrl} h='h-[calc(100vh-20vh)]' />
            </Typhography>
        </Modal>
    );
};
