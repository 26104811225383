import { Button, IconInput, LoadingSpinner, Message, Typhography } from 'dfh-ui-library';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ILoginProps, passwordArgProps, validationProps } from 'utils/types/auth.type';
import { inputTypeInit, signupInitValidationValues } from 'utils/init';
import loginHOC from '../index';

const NewPassword = (props: ILoginProps) => {
    const [passwordArgs, setPasswordArgs] = useState<passwordArgProps>(inputTypeInit);
    const [confPasswordArgs, setConfPasswordArgs] = useState<passwordArgProps>(inputTypeInit);
    const [valSpecs, setValSpecs] = useState<validationProps>(signupInitValidationValues);

    const [searchParams, _setSearchParams] = useSearchParams();
    const confirmationCode = searchParams.get('confirmation_code');
    const email = searchParams.get('email');

    // Check Query Params
    useEffect(() => {
        if (!confirmationCode || !email) props.navigateMethods.navigateToSignIn();
    });

    // New password form config
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Password is required')
                .matches(
                    /^(?=.*[a-zA-Z]).{8,}$/,
                    'Password must be at least 8 characters long and contain at least one letter.',
                )
                .test(
                    'custom-validation',
                    'Password must contain at least one number and one special character',
                    (value) => {
                        return validatePassword(value || '');
                    },
                ),
            confirmPassword: Yup.string()
                .required('Confirm password is required')
                .test('custom-validation-for-confirm-password', 'Confirm password is incorrect', (value) => {
                    return validateConfirmPassword(value || '');
                }),
        }),
        onSubmit: async (values) => {
            const newPassResult = await props.apiMethods.newPassword({
                email: email || '',
                verificationCode: confirmationCode || '',
                newPassword: values.password,
            });
            if (newPassResult?.data) {
                formik.resetForm();
            }
            props.navigateMethods.navigateToSignIn();
        },
    });

    // Custom validation for password
    const validatePassword = (password: string): boolean => {
        const hasNumber = /\d/.test(password);
        const hasSpecialCharacter = /[!@#$%^&*]/.test(password);
        const valData: validationProps = {
            hasNumber: hasNumber,
            hasSpesCharactor: hasSpecialCharacter,
            hasAllCharactors: password.length >= 8,
        };
        setValSpecs(valData);
        return hasNumber && hasSpecialCharacter;
    };

    // Custom validation for confirm password
    const validateConfirmPassword = (confirmPassword: string): boolean => {
        return confirmPassword === formik.values.password;
    };

    // Show/Hide Password
    const showHidePassword = () => {
        if (passwordArgs.type === 'password') {
            setPasswordArgs({
                type: 'text',
                rightIcon: 'EyePswOff',
            });
        } else {
            setPasswordArgs({
                type: 'password',
                rightIcon: 'EyePsw',
            });
        }
    };

    // Show/Hide Confirm Password
    const showHideConfirmPassword = () => {
        if (confPasswordArgs.type === 'password') {
            setConfPasswordArgs({
                type: 'text',
                rightIcon: 'EyePswOff',
            });
        } else {
            setConfPasswordArgs({
                type: 'password',
                rightIcon: 'EyePsw',
            });
        }
    };

    return (
        <Typhography component='div' additionalClasses='relative'>
            {props.isLoading && <LoadingSpinner />}
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <Typhography type='authHeader'>Reset your password</Typhography>
            <form onSubmit={formik.handleSubmit}>
                <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography component='div'>
                            <Typhography additionalClasses='flex flex-col relative'>
                                <IconInput
                                    iconClassLeft='top-4 left-4'
                                    iconClassRight='right-0 right-1.5 top-4'
                                    iconOnClick={showHidePassword}
                                    isIconLeft
                                    isIconRight
                                    leftIcon='Password'
                                    type={passwordArgs.type}
                                    rightIcon={passwordArgs.rightIcon}
                                    label='New Password'
                                    placeholder='*******'
                                    name='password'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.password && formik.errors.password ? formik.errors.password : ''
                                    }
                                    isAdditionalErrorInput={true}
                                    additionalErrorClasses='text-red-500 text-xs pt-1'
                                    additionalClasses={formik.errors.password ? 'border-red-500' : ''}
                                    minLength={8}
                                    isBorderedError={formik.errors.password ? true : false}
                                />
                            </Typhography>
                        </Typhography>
                    </Typhography>
                    <Typhography additionalClasses='py-2'>
                        <Typhography>
                            <Typhography additionalClasses='flex flex-col relative'>
                                <IconInput
                                    iconClassLeft='top-4 left-4'
                                    iconClassRight='right-0 right-1.5 top-4'
                                    iconOnClick={showHideConfirmPassword}
                                    isIconLeft
                                    isIconRight
                                    leftIcon='Password'
                                    type={confPasswordArgs.type}
                                    rightIcon={confPasswordArgs.rightIcon}
                                    label='Confirm Password'
                                    placeholder='*******'
                                    name='confirmPassword'
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.confirmPassword && formik.errors.confirmPassword
                                            ? formik.errors.confirmPassword
                                            : ''
                                    }
                                    isAdditionalErrorInput={true}
                                    additionalErrorClasses='text-red-500 text-xs pt-1'
                                    additionalClasses={formik.errors.confirmPassword ? 'border-red-500' : ''}
                                    minLength={8}
                                    isBorderedError={formik.errors.confirmPassword ? true : false}
                                />
                            </Typhography>
                            <Typhography
                                component='div'
                                additionalClasses='flex flex-row justify-start mt-3 mb-2 text-xs'
                            >
                                <Typhography
                                    component='span'
                                    additionalClasses={valSpecs?.hasAllCharactors ? 'text-black' : 'text-grey-400'}
                                >
                                    &#x2714; Min 8 characters
                                </Typhography>
                                <Typhography
                                    component='span'
                                    additionalClasses={valSpecs?.hasNumber ? 'text-black mx-3' : 'text-grey-400 mx-3'}
                                >
                                    &#x2714; 1 Number
                                </Typhography>
                                <Typhography
                                    component='span'
                                    additionalClasses={valSpecs?.hasSpesCharactor ? 'text-black' : 'text-grey-400'}
                                >
                                    &#x2714; 1 Special character
                                </Typhography>
                            </Typhography>
                        </Typhography>
                    </Typhography>

                    <Typhography additionalClasses='my-8'>
                        <Button
                            type='submit'
                            buttonLabel='Reset Password'
                            variants='large'
                            additionalClasses='bg-[#006CD0]'
                            isDisabled={!(formik.isValid && formik.dirty && valSpecs.hasAllCharactors)}
                        ></Button>
                    </Typhography>
                    <Typhography additionalClasses='py-2 text-center'>
                        <Typhography component='p'>
                            <Typhography type='textMediumGray'>Back to</Typhography>
                            <Typhography
                                type='linkTextMedium'
                                additionalClasses='cursor-pointer ml-1'
                                onClick={props.navigateMethods.navigateToSignIn}
                            >
                                Login
                            </Typhography>
                            {/* <Typhography type='textMediumGray' additionalClasses='ml-1'>
                                or
                            </Typhography>

                            <Typhography
                                type='linkTextMedium'
                                additionalClasses='cursor-pointer ml-1 hover:bg-[#006CD0]'
                                onClick={props.navigateMethods.navigateToSignUp}
                            >
                                Sign Up
                            </Typhography> */}
                        </Typhography>
                    </Typhography>
                </Typhography>
            </form>
        </Typhography>
    );
};
const NewPasswordComponent = loginHOC(NewPassword);
export default NewPasswordComponent;
