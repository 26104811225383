import {
    Breadcrumb,
    Button,
    ButtonGroupWithInputs,
    ClickedAction,
    DialogBox,
    Icon,
    ImagePreview,
    InfoCard,
    Input,
    InputDatePicker,
    Label,
    Message,
    PhoneNumberInput,
    Row,
    SearchDropdownWithButton,
    Select,
    SsnInput,
    Textarea,
    TooltipWithChildren,
    Typhography,
} from 'dfh-ui-library';
import patientHOC from '../index';
import { useEffect, useMemo, useState } from 'react';
import ImageCropperModal from 'components/ImageCropper/ImageCropperModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { initPatient } from 'utils/init/patientInit';
import {
    IPatient,
    IPatientProps,
    IPatientRequest,
    PatientConsent,
    PermanentAddressType,
    ResourceCardProps,
} from 'utils/types/patient.type';
import { IImageCreateRequest } from 'utils/types/image.type';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFristLettersFromString, getMappedUserDetailsForChose, getRandomBgColor } from '../helpers/userHelpers';
import { LocalStorageService } from 'utils/storage/LocalStorageService';
import { RootState, useAppSelector } from 'stores/store';
import User from 'utils/types/user.type';
import strings from '../../../assets/language/string.en.json';
import { validation } from 'utils/validationUtils';
import { DeleteModal } from '../components/deleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { get, isArray, isEmpty, isEqual } from 'lodash';
import { WarningModal } from '../components/warningModal';
import moment from 'moment';
import { base64Image, isValidBase64 } from 'utils/imageHelper';
import { ReleaseInfoModal } from '../components/releaseInfoModal';
import LoadingProgress from 'components/Loading/LoadingProgress';
import { PdfPreviewModal } from '../components/PdfPreviewModal';
import { CloseModalIcon, PreviewIcon } from '../helpers/iconHelper';
import { INavigationProps } from 'utils/types/screener.type';
import { WarningTypes } from '../../../utils/enum';
import PatientConsentModal from '../components/PatientConsentModal';
import { showToast } from 'utils/helpers';
import { savePatientDraftValues } from 'stores/patientSlice';

const PatientAdd = (props: IPatientProps) => {
    const dispatch = useDispatch();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [avatarUrl, setAvatarUrl] = useState<string>('');
    const [tempAvatarImg, setTempAvatarImg] = useState<string>('');
    const [cropperOpen, setCropperOpen] = useState<boolean>(false);
    const [imageDelConf, setImageDelConf] = useState<boolean>(false);
    const [userData, setUserData] = useState<User[]>([]);
    const [patient, setPatient] = useState<IPatient>(initPatient);
    const [responsibleIndividual, setResponsibleIndividual] = useState(initPatient.responsibleIndividual);
    // const [externalCaseManagers, setExternalCaseManagers] = useState(initPatient.externalCaseManagers);
    const [responsibleType, setResponsibleType] = useState<ClickedAction>(ClickedAction.NONE);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [delConf, setDelConf] = useState(false);
    const [warningConf, setWarningConf] = useState({ open: false, message: '', type: '', index: 0 });
    const [_initialExecution, setInitialExecution] = useState(true);
    const localStorageService = LocalStorageService();
    const [bgColorRandom, setBgColorRandom] = useState('');

    const primaryLanguages = useSelector((state: RootState) => state.app.primaryLanguages);
    const races = useSelector((state: RootState) => state.app.races);
    const educations = useSelector((state: RootState) => state.app.educations);
    const genders = useSelector((state: RootState) => state.app.genders);
    const housingStatuses = useSelector((state: RootState) => state.app.housingStatuses);
    const mailingAddresses = useSelector((state: RootState) => state.app.mailingAddresses);
    const relationshipStatuses = useSelector((state: RootState) => state.app.relationshipStatuses);
    const ethnicities = useSelector((state: RootState) => state.app.ethnicities);

    // Get the draft values from the patient store
    const patientDraftValues = useSelector((state: RootState) => state.patient.patientDraftValues);
    const location = useLocation();
    const receivedData = location.state;
    const navigate = useNavigate();
    useEffect(() => {
        setBgColorRandom(getRandomBgColor());
    }, []);
    const { patientId } = useParams();
    const {
        data: selectedPatientRes,
        isFetching: selectedPatientIsLoading,
        refetch,
    } = props.getPatient({
        id: patientId || '',
    });

    const { data: resourcesData, isFetching: isResourcesLoading } = props.getResources(patientId ?? '');

    useEffect(() => {
        refetch();
    }, [patientId, refetch]);

    const { data: usersData, isLoading: usersIsLoading } = useAppSelector((state) => state.user);
    const { data: imageData, isLoading: isImageLoading } = props.getImage({
        type: 'patient',
        imageId: get(patient, 'imageId', ''),
    });

    const updateImage = (img: string | undefined) => {
        if (cropperOpen && img && isValidBase64(img) && !isEqual(img, avatarUrl)) {
            setTempAvatarImg(img);
            formik.setFieldValue('imageId', '');
        }
    };

    const deleteImage = () => {
        setAvatarUrl('');
        formik.setFieldValue('imageId', '');
    };

    const onClickHandleImageDelConf = () => {
        setImageDelConf(false);
        deleteImage();
    };

    // Handle image cropper modal
    const closeCropper = (save: boolean) => {
        if (!save && avatarUrl === '') setAvatarUrl('');
        if (save) setAvatarUrl(tempAvatarImg);
        setCropperOpen(false);
    };

    // Formik validations
    const validations = {
        firstName: validation.firstNameValidation,
        lastName: validation.lastNameValidation,
        middleName: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Middle name should contain only alphabetic characters and spaces')
            .max(35, 'Full name must be 35 characters or less'),
        email: validation.emailValidation,
        responsibleIndividual: Yup.object().shape({
            responsibleIndividualUnregistered: Yup.object().shape({
                email: validation.emailValidation,
            }),
        }).nullable(),
        externalCaseManagers: Yup.array().of(
            Yup.object().shape({
                fullName: Yup.string()
                    .test('is-not-blank', 'Please enter a valid full name for the case manager', (value) =>
                        value ? value.trim().length > 0 : false
                    )
                    .matches(/^[A-Za-z\s]+$/, 'Full name should contain only alphabetic characters and spaces')
                    .max(35, 'Full name must be 35 characters or less'),
                // .matches(/^[A-Za-z\s]+$/, 'Full name should contain only alphabetic characters and spaces')
                // .max(35, 'Full name must be 35 characters or less')
                // .required('Please enter a valid full name for the case manager'),
                email: validation.emailValidation,
                phoneNumber: Yup.string().test(
                    'phoneNumber',
                    'Invalid phone number',
                    (value) => value === undefined || value?.length === 15,
                ),
            }),
        ),
        phoneNumber: Yup.string().test(
            'phoneNumber',
            'Invalid phone number',
            (value) => value === undefined || value?.length === 15,
        ),
        emergencyContact: Yup.string().test(
            'emergencyContact',
            'Invalid phone number',
            (value) => value === undefined || value?.length === 15,
        ),
        personalIncome: Yup.string().test(
            'personalIncome',
            'Invalid personal income',
            (value) => value === undefined || Number(value) > -1,
        ),
        householdIncome: Yup.string().test(
            'householdIncome',
            'Invalid household income',
            (value) => value === undefined || Number(value) > -1,
        ),
        householdSize: Yup.string().test(
            'householdSize',
            'Invalid household size',
            (value) => value === undefined || Number(value) > 0,
        ),
    };

    // Patient add / edit form config
    const formik = useFormik({
        initialValues: initPatient,
        validationSchema: Yup.object(validations),
        onSubmit: async (values) => {

            if (isFormSubmitted) {
                if (!hasConsentGiven) {
                    showToast('Please provide the consent to save patient information', 'warning');
                    return;
                }

                if (avatarUrl && formik.values.imageId === '') {
                    const req: IImageCreateRequest = {
                        type: 'patient',
                        content: avatarUrl,
                    };
                    const imageResult = await props.saveImage(req);
                    if (imageResult && imageResult.data) {
                        values.imageId = imageResult.data.data.imageId;
                    }
                }
                if (values.responsibleIndividual?._id === '') {
                    values.responsibleIndividual = null;
                }
                let result;
                const req: IPatient = {
                    ...formik.values,
                    dob: moment(formik.values.dob).format('YYYY-MM-DD'),
                    enrollmentDate: moment(formik.values.enrollmentDate).format('YYYY-MM-DD'),
                    themeColor: getRandomBgColor(),
                };
                if (!editMode) {
                    result = await props.createPatient(req);
                } else {
                    result = await props.editPatient(req);
                }
                if (result && result.data) {
                    formik.resetForm();
                    dispatch(savePatientDraftValues(null));
                    if (patientId) {
                        props.navigateToView(patientId, true);
                    } else {
                        props.navigateToPatients(true);
                    }
                }
            }
        },
    });


    const hasConsentGiven = formik.values.patientConsent?.consentStatus === 'CONSENT GIVEN';

    // Click on cancel
    const cancelAdd = () => {
        dispatch(savePatientDraftValues(null));
        if (patientId) {
            props.navigateToView(patientId, true);
        } else {
            props.navigateToPatients(false);
        }
    };

    const getUsers = () => {
        if (usersData) {
            setUserData(usersData);
        }
    };

    const handleDobChange = (date: Date | null) => {
        formik.setFieldValue('dob', date);
    };

    const handleEnrollmentDateChange = (date: Date | null) => {
        formik.setFieldValue('enrollmentDate', date);
    };

    const handleSocialSecurityNumberChange = (value: string | null) => {
        formik.setFieldValue('socialSecurityNumber', value);
    };

    // Add new external case manager
    const addExternalCaseManagers = () => {
        formik.setValues((prevValues) => {
            if (prevValues.externalCaseManagers.length < 4) {
                const externalCaseManagersCopy = [
                    ...prevValues.externalCaseManagers,
                    {
                        fullName: '',
                        email: '',
                        phoneNumber: '',
                        agency: '',
                        notes: '',
                        releaseOfInformation: [],
                    },
                ];
                // setExternalCaseManagers(externalCaseManagersCopy);
                return {
                    ...prevValues,
                    externalCaseManagers: externalCaseManagersCopy,
                };
            } else {
                return {
                    ...prevValues,
                };
            }
        });
    };

    // Remove external case manager
    const handleRemoveManager = (indexToRemove?: number) => {
        formik.setValues((prevValues) => {
            const externalCaseManagersCopy = [...prevValues.externalCaseManagers];
            indexToRemove && externalCaseManagersCopy.splice(indexToRemove, 1);
            // setExternalCaseManagers(externalCaseManagersCopy);
            return {
                ...prevValues,
                externalCaseManagers: externalCaseManagersCopy || [],
            };
        });
        setWarningConf({ open: false, message: '', type: '', index: 0 });
    };

    // Set values for external case managers
    // useEffect(() => {
    //     formik.setValues((prevValues) => {
    //         return {
    //             ...prevValues,
    //             externalCaseManagers: externalCaseManagers,
    //         };
    //     });
    // }, [externalCaseManagers]);

    // Handle Gender selector
    const handleSelect = (selectedOption: any) => {
        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                gender: genders[selectedOption - 1],
            };
        });
    };
    const onGenderValueChange = (value: string) => {
        formik.setValues((prevValues) => {
            return {
                ...prevValues,
                gender: value,
            };
        });
    };
    const getSelectedGender = (): number => {
        return isEqual(formik.values.gender, '')
            ? 0
            : genders.filter((gen) => gen === formik.values.gender).length > 0
                ? genders
                    .map((value, index) => ({
                        id: index + 1,
                        value,
                    }))
                    .filter((gen) => gen.value === formik.values.gender)[0].id
                : 4;
    };

    // Handle Responsible Individuals
    const handleSelectedResponsibleIndividual = (newValues: any) => {
        if (newValues.clickedAction !== responsibleType || newValues.id !== responsibleIndividual?._id) {
            if (newValues.clickedAction === ClickedAction.ADDREGISTERED) {
                setResponsibleType(ClickedAction.ADDREGISTERED);
                setResponsibleIndividual({
                    _id: newValues._id,
                    fullName: newValues.fullName,
                });
            } else if (newValues.clickedAction === ClickedAction.ADDME) {
                setResponsibleType(ClickedAction.ADDME);
                handleAddMeBtnClick();
            }
        }
    };

    // Add me as responsible individual
    const handleAddMeBtnClick = () => {
        const user = localStorageService.getUser();
        const me = userData.find((data) => data.email === user.email);

        if (me) {
            const newResponsibleIndividual = {
                _id: me._id as string,
                fullName: me.fullName,
            };
            setResponsibleIndividual(newResponsibleIndividual);
        }
    };

    const [showTooltip, setShowTooltip] = useState('');

    const onClickHander = (ref: string) => {
        let showVal = '';
        switch (ref) {
            case 'ext':
                showVal = showTooltip === 'ext' ? '' : 'ext';
                break;
            case 'cas':
                showVal = showTooltip === 'cas' ? '' : 'cas';
                break;
            case 'cont':
                showVal = showTooltip === 'cont' ? '' : 'cont';
                break;
            case 'Resp':
                showVal = showTooltip === 'Resp' ? '' : 'Resp';
                break;
        }
        setShowTooltip(showVal);
    };

    const tooltipPropsContact: any = {
        tooltipClick: () => onClickHander('cont'),
        tooltipIcon: 'Info',
        tooltipIconClasses: 'cursor-pointer ml-2',
        tooltipHeading: '',
        tooltipContent: (
            <Typhography component='div' additionalClasses='w-[295px] p-2'>
                <ul className='list-disc text-[#21232C]'>
                    <li className='mb-4'>Cell phone</li>
                    <li className='mb-4'>E-mail</li>
                    <li className='mb-4'>At methadone clinic via counselor, Lara, at (555)-555-5555</li>
                    <li className='mb-4'>Through sister, Monik, at (555)-555-5555</li>
                    <li className=''>In Central Square park, behind tennis court, between 6am - 9am daily</li>
                </ul>
            </Typhography>
        ),
        showTooltip: showTooltip === 'cont',
        color: 'blue',
    };

    // Enable edit mode
    const selectedPatientValues = async (patient: IPatient) => {
        if (patient) {
            // formik.resetForm();
            formik.setValues((prevValues) => {
                const values = {
                    ...prevValues,
                    _id: patient._id,
                    imageId: patient.imageId,
                    firstName: patient.firstName,
                    middleName: patient.middleName,
                    lastName: patient.lastName,
                    enrollmentDate: patient.enrollmentDate,
                    race: patient.race,
                    ethnicity: patient.ethnicity,
                    relationshipStatus: patient.relationshipStatus,
                    primaryLanguage: patient.primaryLanguage,
                    gender: patient.gender,
                    socialSecurityNumber: patient.socialSecurityNumber,
                    personalIncome: patient.personalIncome,
                    householdIncome: patient.householdIncome,
                    householdSize: patient.householdSize,
                    email: patient.email,
                    phoneNumber: patient.phoneNumber,
                    medicalProvider: patient.medicalProvider,
                    bestWayToContact: patient.bestWayToContact,
                    emergencyContactName: patient.emergencyContactName,
                    emergencyContactRelationship: patient.emergencyContactRelationship,
                    emergencyContact: patient.emergencyContact,
                    housingStatus: patient.housingStatus,
                    mailingAddress: patient.mailingAddress,
                    address: patient.address,
                    externalCaseManagers: patient.externalCaseManagers,
                    patientConsent: patient?.patientConsent
                };
                if (patient.dob != null) values.dob = patient.dob;
                return values;
            });
            if (patient.responsibleIndividual) {
                setResponsibleIndividual({
                    _id: patient.responsibleIndividual._id,
                    fullName: patient.responsibleIndividual.fullName,
                });
            }
        }
    };

    // Delete patient
    const deletePatient = async () => {
        if (editMode && formik.values._id) {
            setDelConf(false);
            const req: IPatientRequest = {
                id: formik.values._id,
            };
            const result = await props.deletePatient(req);
            if (result && result.data) {
                props.navigateToDelete(formik.values._id, formik.values.firstName + ' ' + formik.values.lastName);
                formik.resetForm();
            }
        }
    };

    useEffect(() => {
        getUsers(); // Fetch responsible users
        if (!isEqual(patientId, undefined)) {
            if (selectedPatientRes?.data) {
                if (receivedData?.caseMngerIndex > -1) {
                    const caseMngerIndex = receivedData?.caseMngerIndex;
                    setCurrentExcmgIndex(receivedData?.caseMngerIndex);
                    if (!isEmpty(receivedData?.fileId)) {
                        const fileData: ResourceCardProps = {
                            title: '',
                            fileSize: `0 KB`,
                            progress: 10,
                            isShowPreview: true,
                            dateCreated: moment(new Date().toISOString()).format('MM/DD/YYYY'),
                            signedUrl: '',
                            fileId: receivedData?.fileId,
                        };
                        // const updatedExternalCaseManagers = isArray(selectedPatientRes.data.externalCaseManagers) ? [...selectedPatientRes.data.externalCaseManagers] : [];
                        const updatedExternalCaseManagers = isArray(patientDraftValues?.externalCaseManagers) ? [...patientDraftValues?.externalCaseManagers] : []
                        if (caseMngerIndex !== -1) {
                            updatedExternalCaseManagers[caseMngerIndex] = {
                                ...updatedExternalCaseManagers[caseMngerIndex],
                                releaseOfInformation: [
                                    ...(updatedExternalCaseManagers[caseMngerIndex]?.releaseOfInformation || []),
                                    fileData,
                                ],
                            };
                        } else {
                            console.warn('Case manager not found. Please check the index or data.');
                        }
                        addReleaseInfo(
                            receivedData?.caseMngerIndex,
                            updatedExternalCaseManagers[caseMngerIndex]?.releaseOfInformation,
                        );
                    } else {
                        addReleaseInfo(
                            receivedData?.caseMngerIndex,
                            selectedPatientRes?.data?.externalCaseManagers[caseMngerIndex]?.releaseOfInformation,
                        );
                    }

                    navigate(location.pathname, { replace: true, state: {} });
                }

                const patient =  isEmpty(patientDraftValues?._id) ? selectedPatientRes.data : patientDraftValues as IPatient;

                // if(!isEmpty(patientDraftValues?.externalCaseManagers)){
                //     patient.externalCaseManagers = patientDraftValues?.externalCaseManagers;
                // }

                console.log("patient draft------->", patient);
                setEditMode(true);
                setPatient(patient);
                selectedPatientValues(patient);
            }

            if (editMode && !isImageLoading && imageData?.data?.signedUrl) {
                // Set edit patient image
                if (formik.values.imageId !== '') setAvatarUrl(imageData.data.signedUrl);
            }
        } else {
            setInitialExecution(false);
        }
    }, [selectedPatientIsLoading, usersIsLoading, isImageLoading, imageData, selectedPatientRes]);

    useEffect(() => {
        formik.setValues((prevValues) => ({
            ...prevValues,
            responsibleIndividual: {
                _id: responsibleIndividual?._id ? responsibleIndividual._id : '',
                fullName: responsibleIndividual?.fullName ? responsibleIndividual.fullName : '',

            },
        }));
    }, [responsibleIndividual]);

    const [showAddInfoModal, setShowAddInfoModal] = useState<boolean>(false);
    const [currentExcmgIndex, setCurrentExcmgIndex] = useState<number>(-1);
    const [currentResourcesInfo, setCurrentResourcesInfo] = useState<ResourceCardProps[]>([]);
    const [currentExistingRes, seturrentExistingRes] = useState<any[]>([]);
    const [showPatientConsentModal, setShowPatientConsentModal] = useState<boolean>(false);

    useEffect(() => {
        resourcesData && seturrentExistingRes(resourcesData.data);
    }, [resourcesData]);

    const addReleaseInfo = (currentIndex: number, info?: ResourceCardProps[]) => {
        setCurrentExcmgIndex(currentIndex);
        setShowAddInfoModal(!showAddInfoModal);
        info && setCurrentResourcesInfo(info);
    };

    const updateCaseManagerInfo = (info: ResourceCardProps[]) => {
        formik.setValues((prevValues) => {
            // Create a copy of the current externalCaseManagers array
            const updatedCaseManagers = [...prevValues.externalCaseManagers];
            // Ensure unique ResourceCardProps entries
            const currentInfoSet = new Set(
                updatedCaseManagers[currentExcmgIndex].releaseOfInformation?.map((item: any) => JSON.stringify(item)) ||
                [],
            );
            info.forEach((item) => currentInfoSet.add(JSON.stringify(item)));
            const uniqueInfoArray = Array.from(currentInfoSet).map((item: any) => JSON.parse(item));
            // Update the releaseOfInformation for the specific index
            if (updatedCaseManagers[currentExcmgIndex]) {
                updatedCaseManagers[currentExcmgIndex] = {
                    ...updatedCaseManagers[currentExcmgIndex],
                    releaseOfInformation: uniqueInfoArray,
                };
            }

            // Return the new state
            return {
                ...prevValues,
                externalCaseManagers: updatedCaseManagers,
            };
        });
        handleCloseModal();
    };

    const deleteResources = async (index: number, info: ResourceCardProps) => {
        // const response = info.fileId && (await props.deleteResourceInfo(info.fileId));

        formik.setValues((prevValues) => {
            // Create a copy of the current externalCaseManagers array
            const updatedCaseManagers = [...prevValues.externalCaseManagers];

            // Update the releaseOfInformation for the specific index
            if (updatedCaseManagers[index]) {
                updatedCaseManagers[index] = {
                    ...updatedCaseManagers[index],
                    releaseOfInformation:
                        updatedCaseManagers[index].releaseOfInformation?.filter(
                            (item) => item.fileId !== info.fileId,
                        ) || [],
                };
            }

            return {
                ...prevValues,
                externalCaseManagers: updatedCaseManagers,
            };
        });
    };

    const handleCloseModal = () => {
        setShowAddInfoModal(false);
    };

    const navigateToLetter = async (idx: number = -1) => {
        patientId && (await props.getPatientLetterSchemaReleaseInfo(patientId));
        const navProps: INavigationProps = {
            id: patientId ?? '',
            name: `${patient?.firstName || ''} ${patient?.lastName || ''}`,
            releaseInfo: { index: idx },
        };
        // save the draft patient values
        dispatch(savePatientDraftValues(formik.values));
        props.navigateToLetterView(navProps);
    };

    const savePatientResource = async (req: IImageCreateRequest) => {
        const response = (await props.uploadResourceInfo(req));
        if (response) {
            return response;
        }
    };

    const getLoadingState = (): boolean => {
        const ldState = props.isLoading || selectedPatientIsLoading || isResourcesLoading;
        return ldState;
    };

    const [viewReleaseInfoPdf, setViewReleaseInfoPdf] = useState<boolean>(false);
    const [pdfReleaseInfoUrl, setPdfReleaseInfoUrl] = useState<string>('');

    const handleCloseResourceModal = () => {
        setViewReleaseInfoPdf(false);
    };

    const previewFile = async (info: ResourceCardProps) => {
        if (info.fileId) {
            const response = info.fileId && (await getRealeaeFile(info.fileId));
            if (response) {
                setViewReleaseInfoPdf(true);
                response.signedUrl && setPdfReleaseInfoUrl(response.signedUrl);
            } else {
                setViewReleaseInfoPdf(true);
                info.signedUrl && setPdfReleaseInfoUrl(info.signedUrl);
            }
        }
    };

    const previewfilesOnModal = async (fileId: string, uploadType?: string) => {
        const response = await getRealeaeFile(fileId, uploadType);
        if (response != null) {
            setViewReleaseInfoPdf(true);
            response.signedUrl && setPdfReleaseInfoUrl(response.signedUrl);
        }
    };

    const getRealeaeFile = async (fileId: string, uploadType: string = 'release-of-info') => {
        if (uploadType === 'Letter' || uploadType === 'Form') {
            uploadType = 'solution-variant-gen';
        } else if (uploadType === 'Upload') {
            uploadType = 'patient-resource';
        }
        const response = fileId && (await props.getReleaseInfoFiles({ fileId: fileId, type: uploadType }));
        if (response) {
            return response.data.data;
        } else {
            return null;
        }
    };

    const displayConsentText = useMemo(() => {
        if (formik.values.patientConsent && formik.values.patientConsent.consentStatus === 'CONSENT GIVEN') {
            const displayDate = moment(new Date(formik.values.patientConsent?.consentDate as string)).format('MM/DD/YYYY')
            return (<Typhography component='div' additionalClasses='flex text-[14px] whitespace-nowrap'>
                Consent obtained by&nbsp;<span className='font-medium'>{formik?.values?.patientConsent?.consentBy}</span>&nbsp;on&nbsp;<span className='font-medium'>{displayDate as string}</span>
            </Typhography>)
        }
    }, [formik.values.patientConsent]);

    return (
        <>
            <div className='relative'>{getLoadingState() && <LoadingProgress isLoading={getLoadingState()} />}</div>

            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}
            <div className='pt-8'>
                <Row additionalClasses='relative'>
                    <Breadcrumb
                        singleArrow={true}
                        items={[
                            {
                                label: 'Patients',
                                link: '/',
                                navAction: () => navigate('/'),
                            },
                            {
                                label: !editMode ? 'Add new patient' : 'Edit patient',
                            },
                        ]}
                    />
                </Row>
                <form
                    onSubmit={formik.handleSubmit}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') event.preventDefault();
                    }}
                >
                    <Typhography component='div' additionalClasses='flex flex-row justify-between items-center my-4'>
                        <Typhography component='div' additionalClasses='col-start-1 col-span-2'>
                            {(!editMode && (
                                <Typhography type='header1' additionalClasses='flex pt-3'>
                                    Add new Patient
                                </Typhography>
                            )) || (
                                    <Typhography type='header1' additionalClasses='flex pt-3'>
                                        Edit Patient
                                    </Typhography>
                                )}
                        </Typhography>
                        <Typhography component='div' additionalClasses='flex items-center'>
                            <Typhography component='div' additionalClasses='flex items-center gap-4'>
                                {displayConsentText}
                                {editMode && (
                                    <Typhography
                                        component='div'
                                        additionalClasses='col-start-13 col-end-14 flex items-center justify-center'
                                    >
                                        <Button
                                            additionalClasses='text-btn-pinkRead text-21s font-medium justify-center p-0 py-3'
                                            buttonLabel='Delete Patient'
                                            data-testId='InputField-id'
                                            iconAlignment='left'
                                            iconType='TrashRed'
                                            isIconEnabled
                                            variants='primaryWhiteNoBoader'
                                            onClick={() => setDelConf(true)}
                                        />
                                    </Typhography>
                                )}
                                <Typhography component='div'>
                                    <Button
                                        buttonLabel='Cancel'
                                        variants='primaryWhite'
                                        onClick={() => setWarningConf({ open: true, message: "You have changed the patient's information.\nAre you sure you don't want to save the changes?", type: '', index: 0 })}
                                    />
                                </Typhography>
                                <Typhography component='div'>
                                    {hasConsentGiven ? <Button
                                        type='submit'
                                        buttonLabel={!editMode ? 'Create patient' : 'Save'}
                                        additionalClasses={editMode ? 'px-6' : ''}
                                        data-testId='InputField-id'
                                        variants='primaryBlue'
                                        onClick={() => setIsFormSubmitted(true)}
                                    /> :
                                        <Button
                                            buttonLabel={'Collect consent'}
                                            additionalClasses={editMode ? 'px-6' : ''}
                                            data-testId='InputField-id'
                                            variants='primaryBlue'
                                            onClick={() => setShowPatientConsentModal(true)}
                                        />}

                                </Typhography>
                            </Typhography>
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='grid grid-cols-6'>
                        <Typhography component='div' additionalClasses='col-start-1 col-end-2 mr-7'>
                            <Typhography additionalClasses='flex-col w-auto inline-block p-2'>
                                <ImagePreview
                                    text={getFristLettersFromString(
                                        `${formik.values.firstName.replace(/\s/g, '')} ${formik.values.lastName.replace(
                                            /\s/g,
                                            '',
                                        )}`,
                                    )}
                                    isSmall={false}
                                    imageUrl={avatarUrl}
                                    bgColor={bgColorRandom}
                                    textClasses={'text-42s'}
                                    isAddEditPatient={true}
                                />
                                <Typhography component='div' additionalClasses='mt-4'>
                                    <Button
                                        buttonLabel={
                                            (editMode && formik.values.imageId) || !isEqual(avatarUrl, '')
                                                ? 'Change photo'
                                                : 'Upload photo'
                                        }
                                        data-testId='InputField-id'
                                        iconAlignment='left'
                                        iconType='Upload'
                                        isIconEnabled
                                        variants='primaryWhite'
                                        onClick={() => setCropperOpen(true)}
                                    />
                                    {avatarUrl && (
                                        <Button
                                            additionalClasses='text-btn-pinkRead text-21s font-medium justify-center'
                                            buttonLabel='Delete Photo'
                                            data-testId='InputField-id'
                                            iconAlignment='left'
                                            iconType='TrashRed'
                                            isIconEnabled
                                            variants='primaryWhiteNoBoader'
                                            onClick={() => setImageDelConf(true)}
                                        />
                                    )}
                                </Typhography>
                            </Typhography>
                        </Typhography>
                        <Typhography component='div' additionalClasses='mt-3 col-start-2 col-end-7'>
                            <Row>
                                <Typhography type='textSectionHeader' additionalClasses='flex'>
                                    General info
                                </Typhography>
                            </Row>
                            <Typhography component='div' additionalClasses='mt-7'>
                                <Typhography component='div' additionalClasses='grid grid-cols-4 gap-5'>
                                    <Typhography component='div'>
                                        <Input
                                            name='firstName'
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isRequired
                                            error={
                                                formik.touched.firstName && formik.errors.firstName
                                                    ? formik.errors.firstName
                                                    : ''
                                            }
                                            isAdditionalErrorInput={true}
                                            additionalErrorClasses='text-red-500 text-xs pt-1'
                                            isBorderedError={formik.errors.firstName ? true : false}
                                            label='First name'
                                            placeholder='First name'
                                            labelClasses='pb-1'
                                            labelType='formLabelMedeum'
                                        />
                                    </Typhography>
                                    <Typhography component='div'>
                                        <Input
                                            name='middleName'
                                            value={formik.values.middleName}
                                            error={
                                                formik.touched.middleName && formik.errors.middleName
                                                    ? formik.errors.middleName
                                                    : ''
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isAdditionalErrorInput={true}
                                            label='Middle name'
                                            placeholder='Middle name'
                                            labelClasses='pb-1'
                                            labelType='formLabelMedeum'
                                        />
                                    </Typhography>
                                    <Typhography component='div'>
                                        <Input
                                            name='lastName'
                                            isRequired
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.lastName && formik.errors.lastName
                                                    ? formik.errors.lastName
                                                    : ''
                                            }
                                            isAdditionalErrorInput={true}
                                            additionalErrorClasses='text-red-500 text-xs pt-1'
                                            isBorderedError={formik.errors.lastName ? true : false}
                                            label='Last name'
                                            placeholder='Last name'
                                            labelClasses='pb-1'
                                            labelType='formLabelMedeum'
                                        />
                                    </Typhography>
                                    <Typhography component='div'>
                                        <InputDatePicker
                                            onChange={handleDobChange}
                                            selectedDate={formik.values.dob ? new Date(formik.values.dob) : null}
                                            label={'Date of birth'}
                                            labelType='formLabelMedeum'
                                            isRequired
                                            error={
                                                formik.values.dob == null && isFormSubmitted
                                                    ? 'Date of birth is required'
                                                    : ''
                                            }
                                            isAdditionalErrorInput={true}
                                            additionalErrorClasses='text-red-500 text-xs pt-1'
                                            isBorderedError={
                                                formik.values.dob == null && isFormSubmitted ? true : false
                                            }
                                            placeHolder='MM-DD-YYYY'
                                            height='h-11 mt-1'
                                            inputVariant='form'
                                        />
                                    </Typhography>
                                </Typhography>
                            </Typhography>
                            <Typhography component='div' additionalClasses='mt-7'>
                                <Typhography component='div' additionalClasses='grid grid-cols-4 gap-5'>
                                    <Typhography component='div' additionalClasses='pt-1.5'>
                                        <Select
                                            name='race'
                                            value={formik.values.race}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultText='Select race'
                                            selectType='regular'
                                            label='Race'
                                            secondaryText='(self-identified)'
                                            labelClasses='mb-1'
                                            isIcon={false}
                                            labelType='formLabelMedeum'
                                            options={races.map((race) => ({
                                                displayName: race,
                                                value: race,
                                            }))}
                                            additionalClasses='py-3 '
                                        />
                                    </Typhography>
                                    <Typhography component='div' additionalClasses='pt-1.5'>
                                        <Select
                                            name='relationshipStatus'
                                            value={formik.values.relationshipStatus}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultText='Relationship status'
                                            selectType='regular'
                                            label='Relationship status'
                                            labelClasses='mb-1'
                                            isIcon={false}
                                            labelType='formLabelMedeum'
                                            options={relationshipStatuses.map((relationshipStatus) => ({
                                                displayName: relationshipStatus,
                                                value: relationshipStatus,
                                            }))}
                                            additionalClasses='py-3 '
                                        />
                                    </Typhography>
                                    <Typhography component='div' additionalClasses='col-span-2'>
                                        <ButtonGroupWithInputs
                                            options={genders.map((value, index) => ({
                                                id: index + 1,
                                                value,
                                            }))}
                                            onSelect={handleSelect}
                                            label='Gender'
                                            selected={getSelectedGender()}
                                            inputObjects={[
                                                {
                                                    id: 1,
                                                    name: 'other',
                                                    placeholder: 'Other',
                                                    value:
                                                        genders.filter((gen) => gen === formik.values.gender).length ===
                                                            0
                                                            ? formik.values.gender
                                                            : '',
                                                },
                                            ]}
                                            orderBy='button'
                                            secondaryComponentsVisible={
                                                !isEqual(formik.values.gender, '') &&
                                                (genders.filter((gen) => gen === formik.values.gender).length === 0 ||
                                                    formik.values.gender === 'Other')
                                            }
                                            buttonGroupCol='grid-cols-4'
                                            onValueChange={onGenderValueChange}
                                            additionalClasses='bottom-0'
                                        />
                                    </Typhography>
                                    <Typhography component='div'></Typhography>
                                </Typhography>
                            </Typhography>
                            <Typhography component='div' additionalClasses='mt-7'>
                                <Typhography component='div' additionalClasses='grid grid-cols-4 gap-5'>
                                    <Typhography component='div' additionalClasses=''>
                                        <Select
                                            name='ethnicity'
                                            value={formik.values.ethnicity}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultText='Ethnicity'
                                            selectType='regular'
                                            label='Ethnicity'
                                            labelClasses='mb-1'
                                            isIcon={false}
                                            labelType='formLabelMedeum'
                                            options={ethnicities.map((ethnicity) => ({
                                                displayName: ethnicity,
                                                value: ethnicity,
                                            }))}
                                            additionalClasses='py-3'
                                        />
                                    </Typhography>
                                    <Typhography component='div' additionalClasses=''>
                                        <Select
                                            name='primaryLanguage'
                                            value={formik.values.primaryLanguage}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultText='Select language'
                                            selectType='regular'
                                            label='Primary Language'
                                            labelClasses='mb-1'
                                            isIcon={false}
                                            labelType='formLabelMedeum'
                                            options={primaryLanguages.map((primaryLanguage) => ({
                                                displayName: primaryLanguage,
                                                value: primaryLanguage,
                                            }))}
                                            additionalClasses='py-3'
                                        />
                                    </Typhography>
                                    <Typhography component='div'>
                                        <Select
                                            name='education'
                                            value={formik.values.education}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultText='Select education'
                                            selectType='regular'
                                            label='Education'
                                            labelClasses='mb-1'
                                            isIcon={false}
                                            labelType='formLabelMedeum'
                                            options={educations.map((education) => ({
                                                displayName: education,
                                                value: education,
                                            }))}
                                            additionalClasses='py-3'
                                        />
                                    </Typhography>
                                    <Typhography component='div'>
                                        <Input
                                            name='medicalProvider'
                                            value={formik.values.medicalProvider}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            label='Medical provider'
                                            placeholder='Medical provider'
                                            labelType='formLabelMedeum'
                                        />
                                    </Typhography>
                                </Typhography>
                            </Typhography>
                            <Typhography component='div' additionalClasses='mt-7'>
                                <Typhography component='div' additionalClasses='grid grid-cols-4 gap-5'>
                                    <Typhography component='div'>
                                        {userData && userData.length && (
                                            <SearchDropdownWithButton
                                                options={userData && getMappedUserDetailsForChose(userData)}
                                                label='Responsible Individual'
                                                listHeader='Choose Responsible Individual'
                                                labelType='formLabelMedeum'
                                                onSelectedValuesChange={handleSelectedResponsibleIndividual}
                                                defaultValue='Select responsible individual'
                                                error={''}
                                                selected={responsibleIndividual?._id}
                                                clearAction={() => {
                                                    setResponsibleIndividual(initPatient.responsibleIndividual);
                                                    setResponsibleType(ClickedAction.NONE);
                                                }}
                                                buttonProps={[
                                                    {
                                                        btnLabel: 'Add me',
                                                        btnType: 'ADD',
                                                        btnAction: () => {
                                                            handleSelectedResponsibleIndividual({
                                                                clickedAction: ClickedAction.ADDME,
                                                            });
                                                        },
                                                        variants: 'primaryWhite',
                                                    },
                                                ]}
                                            />
                                        )}
                                    </Typhography>
                                    <Typhography component='div' additionalClasses=''>
                                        <InputDatePicker
                                            onChange={handleEnrollmentDateChange}
                                            selectedDate={
                                                formik.values.enrollmentDate
                                                    ? new Date(formik.values.enrollmentDate)
                                                    : null
                                            }
                                            label={'Enrollment date'}
                                            labelType='formLabelMedeum'
                                            additionalClasses='text-21s'
                                            iconClasses='d-none'
                                            inputVariant='form'
                                            placeHolder='MM-DD-YYYY'
                                            height='h-11 mt-1'
                                        />
                                    </Typhography>
                                    <Typhography component='div' additionalClasses=''>
                                        <SsnInput
                                            name='socialSecurityNumber'
                                            value={formik.values.socialSecurityNumber}
                                            onChange={handleSocialSecurityNumberChange}
                                            error={
                                                formik.touched.socialSecurityNumber &&
                                                    formik.errors.socialSecurityNumber
                                                    ? formik.errors.socialSecurityNumber
                                                    : ''
                                            }
                                            isAdditionalErrorInput={true}
                                            additionalErrorClasses='text-red-500 text-xs pt-1'
                                            iconClassRight='right-1.5 top-8'
                                            isIconRight={true}
                                            isBorderedError={formik.errors.socialSecurityNumber ? true : false}
                                            label='Social security number'
                                            placeholder='###-##-####'
                                            labelType='formLabelMedeum'
                                        ></SsnInput>
                                    </Typhography>
                                    <Typhography component='div'>
                                        <Input
                                            type='number'
                                            name='personalIncome'
                                            value={formik.values.personalIncome}
                                            placeholder={'Personal income (monthly)'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.personalIncome && formik.errors.personalIncome
                                                    ? formik.errors.personalIncome
                                                    : ''
                                            }
                                            isAdditionalErrorInput={true}
                                            additionalErrorClasses='text-red-500 text-xs pt-1'
                                            isBorderedError={formik.errors.personalIncome ? true : false}
                                            label='Personal income (monthly)'
                                            labelType='formLabelMedeum'
                                        />
                                    </Typhography>
                                </Typhography>
                                <Typhography component='div' additionalClasses='mt-7'>
                                    <Typhography component='div' additionalClasses='grid grid-cols-4 gap-5'>
                                        <Typhography component='div'>
                                            <Input
                                                type='number'
                                                name='householdSize'
                                                value={formik.values.householdSize}
                                                placeholder={'Household size'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.householdSize && formik.errors.householdSize
                                                        ? formik.errors.householdSize
                                                        : ''
                                                }
                                                isAdditionalErrorInput={true}
                                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                                isBorderedError={formik.errors.householdSize ? true : false}
                                                label='Household size'
                                                labelType='formLabelMedeum'
                                            />
                                        </Typhography>
                                        <Typhography component='div'>
                                            <Input
                                                type='number'
                                                name='householdIncome'
                                                value={formik.values.householdIncome}
                                                placeholder={'Household income (monthly)'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.householdIncome && formik.errors.householdIncome
                                                        ? formik.errors.householdIncome
                                                        : ''
                                                }
                                                isAdditionalErrorInput={true}
                                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                                isBorderedError={formik.errors.householdIncome ? true : false}
                                                label='Household income (monthly)'
                                                labelType='formLabelMedeum'
                                            />
                                        </Typhography>
                                    </Typhography>
                                </Typhography>
                            </Typhography>
                            <Typhography component='div' additionalClasses='border  border-x-0 mt-9 py-8'>
                                <Typhography component='div' additionalClasses='flex'>
                                    <Typhography type='textSectionHeader' additionalClasses='flex'>
                                        Contact info
                                    </Typhography>
                                </Typhography>
                                <Typhography component='div' additionalClasses='mt-7'>
                                    <Typhography
                                        component='div'
                                        additionalClasses='grid grid-flow-row-dense grid-cols-3 gap-5'
                                    >
                                        <Typhography component='div'>
                                            <Input
                                                name='email'
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.email && formik.errors.email
                                                        ? formik.errors.email
                                                        : ''
                                                }
                                                isAdditionalErrorInput={true}
                                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                                isBorderedError={formik.errors.email ? true : false}
                                                label='Email'
                                                placeholder='example@gmail.com'
                                                labelType='formLabelMedeum'
                                            />
                                        </Typhography>
                                        <Typhography component='div' additionalClasses='bottom-1.5 relative'>
                                            <PhoneNumberInput
                                                name='phoneNumber'
                                                placeholder={'+1 890-989-0000'}
                                                labelType='formLabelMedeum'
                                                label='Phone number'
                                                error={
                                                    formik.touched.phoneNumber && formik.errors.phoneNumber
                                                        ? formik.errors.phoneNumber
                                                        : ''
                                                }
                                                isAdditionalErrorInput={true}
                                                isBorderedError={true}
                                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                                value={formik.values.phoneNumber}
                                                onChange={(value: any) => formik.setFieldValue(`phoneNumber`, value)}
                                            />
                                        </Typhography>
                                        <Typhography component='div'>
                                            <Input
                                                name='bestWayToContact'
                                                value={formik.values.bestWayToContact}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                label='Best way to contact'
                                                placeholder='Best way to contact'
                                                labelClasses=''
                                                labelType='formLabelMedeum'
                                                enableToolTip={true}
                                                tooltipProps={tooltipPropsContact}
                                            />
                                        </Typhography>

                                        <Typhography component='div'>
                                            <Select
                                                name='housingStatus'
                                                value={formik.values.housingStatus}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                defaultText='Housing status'
                                                selectType='regular'
                                                label='Housing status'
                                                labelClasses='mb-1'
                                                isIcon={false}
                                                labelType='formLabelMedeum'
                                                options={housingStatuses.map((housingStatus: string) => ({
                                                    displayName: housingStatus,
                                                    value: housingStatus,
                                                }))}
                                                additionalClasses='py-3'
                                            />
                                        </Typhography>
                                        <Typhography component='div'>
                                            <Select
                                                name='mailingAddress'
                                                value={formik.values.mailingAddress}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                defaultText='Mailing Address'
                                                selectType='regular'
                                                label='Mailing address'
                                                labelClasses='mb-1'
                                                isIcon={false}
                                                labelType='formLabelMedeum'
                                                options={mailingAddresses.map((mailingAddress: string) => ({
                                                    displayName: mailingAddress,
                                                    value: mailingAddress,
                                                }))}
                                                additionalClasses='py-3'
                                            />
                                        </Typhography>
                                    </Typhography>
                                    {(isEqual(formik.values.mailingAddress, PermanentAddressType.HOUSED) ||
                                        isEqual(
                                            formik.values.mailingAddress,
                                            PermanentAddressType.Homeless_WITH_ADDRESS,
                                        )) && (
                                            <>
                                                <Typhography
                                                    component='div'
                                                    additionalClasses='grid grid-flow-row-dense grid-cols-12 gap-5 mt-7'
                                                >
                                                    <Typhography type='h2' additionalClasses='col-start-1 col-span-12'>
                                                        Permanent Address
                                                    </Typhography>
                                                </Typhography>

                                                <Typhography
                                                    component='div'
                                                    additionalClasses='grid grid-flow-row-dense grid-cols-12 gap-5 mt-7'
                                                >
                                                    <Typhography component='div' additionalClasses='col-span-12'>
                                                        <Input
                                                            name='address.address1'
                                                            value={formik.values.address?.address1}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            label='Address line 1'
                                                            placeholder='Address line 1'
                                                            labelClasses=''
                                                            labelType='formLabelMedeum'
                                                        />
                                                    </Typhography>
                                                </Typhography>
                                                <Typhography
                                                    component='div'
                                                    additionalClasses='grid grid-flow-row-dense grid-cols-12 gap-5 mt-7'
                                                >
                                                    <Typhography component='div' additionalClasses='col-span-12'>
                                                        <Input
                                                            name='address.address2'
                                                            value={formik.values.address?.address2}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            label='Address line 2'
                                                            placeholder='Address line 2'
                                                            labelClasses=''
                                                            labelType='formLabelMedeum'
                                                        />
                                                    </Typhography>
                                                </Typhography>
                                                <Typhography
                                                    component='div'
                                                    additionalClasses='grid grid-flow-row-dense grid-cols-12 gap-5 mt-7'
                                                >
                                                    <Typhography component='div' additionalClasses='col-span-4'>
                                                        <Input
                                                            name='address.city'
                                                            value={formik.values.address?.city}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            label='City'
                                                            placeholder='City'
                                                            labelClasses=''
                                                            labelType='formLabelMedeum'
                                                        />
                                                    </Typhography>
                                                    <Typhography component='div' additionalClasses='col-span-4'>
                                                        <Input
                                                            name='address.state'
                                                            value={formik.values.address?.state}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            label='State'
                                                            placeholder='State'
                                                            labelClasses=''
                                                            labelType='formLabelMedeum'
                                                        />
                                                    </Typhography>
                                                    <Typhography component='div' additionalClasses='col-span-4'>
                                                        <Input
                                                            name='address.zipCode'
                                                            value={formik.values.address?.zipCode}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            label='Zip code'
                                                            placeholder='Zip code'
                                                            labelClasses=''
                                                            labelType='formLabelMedeum'
                                                        />
                                                    </Typhography>
                                                </Typhography>
                                            </>
                                        )}
                                    {isEqual(
                                        formik.values.mailingAddress,
                                        PermanentAddressType.HOMELESS_NO_ADDRESS,
                                    ) && (
                                            <Typhography component='div' additionalClasses='col-start-1 col-span-12 mt-7'>
                                                <InfoCard
                                                    HeaderText='Reminder'
                                                    text='A mailing address is vital for providing social care. Please update this section once you have helped the patient identify an address where they can receive mail.'
                                                />
                                            </Typhography>
                                        )}
                                </Typhography>
                            </Typhography>

                            <Typhography component="div" additionalClasses="border-x-0  py-8">
                                <Typhography component="div" additionalClasses="flex">
                                    <Typhography type="textSectionHeader" additionalClasses="flex">
                                        Emergency contact
                                    </Typhography>
                                </Typhography>
                                <Typhography component="div" additionalClasses="mt-7">
                                    <Typhography
                                        component="div"
                                        additionalClasses="grid grid-flow-row-dense grid-cols-4 gap-5"
                                    >
                                        <Typhography component="div">
                                            <Input
                                                name="emergencyContactName"
                                                value={formik.values.emergencyContactName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.emergencyContactName &&
                                                        formik.errors.emergencyContactName
                                                        ? formik.errors.emergencyContactName
                                                        : ""
                                                }
                                                isAdditionalErrorInput={true}
                                                additionalErrorClasses="text-red-500 text-xs pt-1"
                                                isBorderedError={formik.errors.emergencyContactName ? true : false}
                                                label="Emergency contact name"
                                                placeholder="Emergency contact name"
                                                labelType="formLabelMedeum"
                                            />
                                        </Typhography>
                                        <Typhography component="div">
                                            <Input
                                                name="emergencyContactRelationship"
                                                value={formik.values.emergencyContactRelationship}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.emergencyContactRelationship &&
                                                        formik.errors.emergencyContactRelationship
                                                        ? formik.errors.emergencyContactRelationship
                                                        : ""
                                                }
                                                isAdditionalErrorInput={true}
                                                additionalErrorClasses="text-red-500 text-xs pt-1"
                                                isBorderedError={
                                                    formik.errors.emergencyContactRelationship ? true : false
                                                }
                                                label="Emergency contact relationship"
                                                placeholder="Emergency contact relationship"
                                                labelType="formLabelMedeum"
                                            />
                                        </Typhography>
                                        <Typhography component="div" additionalClasses="bottom-1.5 relative">
                                            <PhoneNumberInput
                                                name="emergencyContact"
                                                placeholder={"+1 890-989-0000"}
                                                labelType="formLabelMedeum"
                                                label="Emergency contact number"
                                                error={
                                                    formik.touched.emergencyContact && formik.errors.emergencyContact
                                                        ? formik.errors.emergencyContact
                                                        : ""
                                                }
                                                isAdditionalErrorInput={true}
                                                isBorderedError={true}
                                                additionalErrorClasses="text-red-500 text-xs pt-1"
                                                value={formik.values.emergencyContact}
                                                onChange={(value: any) =>
                                                    formik.setFieldValue(`emergencyContact`, value)
                                                }
                                            />
                                        </Typhography>
                                    </Typhography>
                                </Typhography>
                            </Typhography>

                            {editMode && <>
                                <Typhography component='div' additionalClasses='mt-10 grid grid-cols-12 gap-4 mb-2'>
                                    <Typhography additionalClasses='flex col-start-1 col-end-4 pt-3' component='div'>
                                        <Typhography type='textSectionHeader' additionalClasses='flex pb-5'>
                                            External Case Manager
                                        </Typhography>
                                        <Typhography>
                                            <TooltipWithChildren
                                                tooltipHeading={''}
                                                additionalClasses='ml-2 mt-1'
                                                tooltipContent={
                                                    <Typhography
                                                        component='div'
                                                        additionalClasses='text-gray-700 text-nowrap'
                                                    >
                                                        <Typhography component='p' additionalClasses='pb-2 text-wrap'>
                                                            {strings.patient.externalTaskTooltip}
                                                        </Typhography>
                                                        <Typhography component='p' additionalClasses='pb-2 text-wrap'>
                                                            {strings.patient.tooltopContent1st}
                                                        </Typhography>
                                                        <Typhography component='p'>
                                                            {strings.patient.tooltopContent2nd}
                                                        </Typhography>
                                                        <Typhography component='p'>
                                                            {strings.patient.tooltopContent3rd}
                                                        </Typhography>
                                                    </Typhography>
                                                }
                                                tooltipIcon='info'
                                                tooltipContentHighlighted={true}
                                            >
                                                <Icon iconType={'Info'} color='blue' />
                                            </TooltipWithChildren>
                                        </Typhography>
                                    </Typhography>
                                    <Typhography
                                        component='div'
                                        additionalClasses='col-span-3 col-start-10 flex justify-end pl-9'
                                    >
                                        {formik?.values?.externalCaseManagers?.length < 4 && (
                                            <Button
                                                buttonLabel='Add one more manager'
                                                data-testId='InputField-id'
                                                iconAlignment='left'
                                                iconType='PlusBtn'
                                                isIconEnabled
                                                variants='primaryWhite'
                                                additionalClasses='text-nowrap'
                                                iconClass='pt-1'
                                                onClick={() => addExternalCaseManagers()}
                                            />
                                        )}
                                    </Typhography>
                                </Typhography>

                                {formik.values.externalCaseManagers.length > 0 ? (
                                    formik.values.externalCaseManagers.map((_item, i) => {
                                        return (
                                            <Typhography
                                                key={i}
                                                component='div'
                                                additionalClasses='grid grid-cols-12 gap-4 border-solid border rounded-md mt-4 p-4'
                                            >
                                                <Typhography type='h2' additionalClasses='col-start-1 col-span-12'>
                                                    Case Manager #{i + 1}
                                                </Typhography>

                                                <Typhography component='div' additionalClasses={`col-start-1 col-span-3`}>
                                                    <Input
                                                        label='Full name'
                                                        placeholder='Full name'
                                                        labelClasses='pb-1'
                                                        labelType='formLabelMedeum'
                                                        isRequired
                                                        name={`externalCaseManagers[${i}].fullName`}
                                                        value={formik.values.externalCaseManagers[i]?.fullName || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.touched.externalCaseManagers?.[i]?.fullName &&
                                                                formik.errors.externalCaseManagers?.[i]?.hasOwnProperty(
                                                                    'fullName',
                                                                )
                                                                ? (
                                                                    formik.errors.externalCaseManagers[i] as {
                                                                        fullName?: string;
                                                                    }
                                                                )?.fullName
                                                                : ''
                                                        }
                                                    />
                                                </Typhography>
                                                <Typhography component='div' additionalClasses='col-start-4 col-span-3'>
                                                    <Input
                                                        label='Email'
                                                        placeholder='example@gmail.com'
                                                        labelClasses='pb-1'
                                                        labelType='formLabelMedeum'
                                                        name={`externalCaseManagers[${i}].email`}
                                                        value={formik.values.externalCaseManagers[i]?.email || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.touched.externalCaseManagers?.[i]?.email &&
                                                                formik.errors.externalCaseManagers?.[i]?.hasOwnProperty('email')
                                                                ? (
                                                                    formik.errors.externalCaseManagers[i] as {
                                                                        email?: string;
                                                                    }
                                                                )?.email
                                                                : ''
                                                        }
                                                        isAdditionalErrorInput={true}
                                                        additionalErrorClasses='text-red-500 text-xs pt-1'
                                                        isBorderedError={
                                                            formik.errors.externalCaseManagers?.[i]?.hasOwnProperty('email')
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Typhography>
                                                <Typhography component='div' additionalClasses='col-start-7 col-span-3'>
                                                    <Typhography component='div' additionalClasses='bottom-1.5 relative'>
                                                        <Typhography component='div' additionalClasses=''>
                                                            <Typhography component='div'>
                                                                <PhoneNumberInput
                                                                    name='phoneNumber'
                                                                    value={
                                                                        formik.values.externalCaseManagers[i]
                                                                            ?.phoneNumber || ''
                                                                    }
                                                                    onChange={(value: any) =>
                                                                        formik.setFieldValue(
                                                                            `externalCaseManagers[${i}].phoneNumber`,
                                                                            value,
                                                                        )
                                                                    }
                                                                    error={
                                                                        formik.touched.externalCaseManagers?.[i]
                                                                            ?.phoneNumber &&
                                                                            formik.errors.externalCaseManagers?.[
                                                                                i
                                                                            ]?.hasOwnProperty('phoneNumber')
                                                                            ? (
                                                                                formik.errors.externalCaseManagers[i] as {
                                                                                    phoneNumber?: string;
                                                                                }
                                                                            )?.phoneNumber
                                                                            : ''
                                                                    }
                                                                    isAdditionalErrorInput={true}
                                                                    isBorderedError={true}
                                                                    additionalErrorClasses='text-red-500 text-xs pt-1'
                                                                    placeholder={'+1 890-989-0000'}
                                                                    labelType='formLabelMedeum'
                                                                    label='Phone number'
                                                                />
                                                            </Typhography>
                                                        </Typhography>
                                                    </Typhography>
                                                </Typhography>
                                                <Typhography component='div' additionalClasses='col-start-10 col-span-3'>
                                                    <Input
                                                        label='Agency'
                                                        placeholder='Agency'
                                                        labelClasses='pb-1'
                                                        labelType='formLabelMedeum'
                                                        name={`externalCaseManagers[${i}].agency`}
                                                        value={formik.values.externalCaseManagers[i]?.agency || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        error={
                                                            formik.touched.externalCaseManagers?.[i]?.agency &&
                                                                formik.errors.externalCaseManagers?.[i]?.hasOwnProperty(
                                                                    'agency',
                                                                )
                                                                ? (
                                                                    formik.errors.externalCaseManagers[i] as {
                                                                        agency?: string;
                                                                    }
                                                                )?.agency
                                                                : ''
                                                        }
                                                        isAdditionalErrorInput={true}
                                                        additionalErrorClasses='text-red-500 text-xs pt-1'
                                                        isBorderedError={
                                                            formik.errors.externalCaseManagers?.[i]?.hasOwnProperty(
                                                                'agency',
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </Typhography>

                                                <Typhography
                                                    component='div'
                                                    additionalClasses='col-start-13 col-end-14 flex items-center justify-center'
                                                >
                                                    <Icon
                                                        iconType={'TrashRed'}
                                                        onClick={() => setWarningConf({ open: true, message: "You are going to delete an external case manager!\nAre you sure you don't want to save the external case manager?", type: WarningTypes.DELETE_EXTERNAL_CASE_MANAGER, index: i })}
                                                        additionalClasses='cursor-pointer'
                                                    />
                                                </Typhography>


                                                <Typhography component='div' additionalClasses={`col-start-1 col-span-12`}>
                                                    <Textarea
                                                        name={`externalCaseManagers[${i}].notes`}
                                                        value={formik.values.externalCaseManagers[i].notes || ''}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        label='Notes'
                                                        placeholder='Type note'
                                                    />
                                                </Typhography>
                                                <Typhography component='div' additionalClasses={`col-start-1 col-span-12`}>
                                                    <Typhography component='div' additionalClasses={`mb-2`}>
                                                        <Label name='releaseInfo' label='Release of Information' />
                                                    </Typhography>
                                                    <Typhography
                                                        component='div'
                                                        additionalClasses='flex gap-2 items-center '
                                                    >
                                                        {formik.values.externalCaseManagers[i]?.releaseOfInformation?.map(
                                                            (info, index) => (
                                                                <Typhography component='div' additionalClasses=''>
                                                                    <Typhography
                                                                        key={index} // Make sure to include a unique key for each item in the loop
                                                                        component='div'
                                                                        additionalClasses={`text-12s font-normal bg-[#F1F5F9] py-1 rounded-[100px] p-2 flex basis-1/${patient?.externalCaseManagers.length} items-center justify-center gap-2 py-2.5`}
                                                                    >
                                                                        <PreviewIcon onClick={() => previewFile(info)} />
                                                                        {`Release of Information ${moment(info.dateCreated).format('MM/DD/YYYY')}`}
                                                                        <CloseModalIcon
                                                                            onClick={() => {
                                                                                deleteResources(i, info);
                                                                            }}
                                                                            w={'16'}
                                                                            h={'16'}
                                                                            classes=''
                                                                        />
                                                                    </Typhography>
                                                                </Typhography>
                                                            ),
                                                        )}
                                                        <Typhography component='div' additionalClasses='w-[250px]'>
                                                            <Button
                                                                buttonLabel='Add Release of Information'
                                                                data-testId='InputField-id'
                                                                iconAlignment='left'
                                                                iconType='PlusBtn'
                                                                iconClass='mt-1'
                                                                isIconEnabled
                                                                variants='primaryWhite'
                                                                onClick={() =>
                                                                    addReleaseInfo(
                                                                        i,
                                                                        formik.values.externalCaseManagers[i]
                                                                            ?.releaseOfInformation,
                                                                    )
                                                                }
                                                            />
                                                        </Typhography>
                                                    </Typhography>
                                                </Typhography>
                                            </Typhography>
                                        );
                                    })
                                ) : (
                                    <>
                                        <Typhography component='div' additionalClasses={`col-start-1 col-span-12`}>
                                            <InfoCard
                                                HeaderText='No external case manager identified'
                                                text='Clients often work with many different agencies. Please add any external case managers identified here to help with care coordination going forward.'
                                            />
                                        </Typhography>
                                    </>
                                )}
                            </>}
                            <Typhography
                                component='div'
                                additionalClasses='col-start-5 col-end-7 flex justify-end mt-4'
                            >
                                <Typhography component='div' additionalClasses='flex gap-4'>
                                    {editMode && (
                                        <Typhography
                                            component='div'
                                            additionalClasses='col-start-13 col-end-14 flex items-center justify-center'
                                        >
                                            <Button
                                                additionalClasses='text-btn-pinkRead text-21s font-medium justify-center p-0 py-3'
                                                buttonLabel='Delete Patient'
                                                data-testId='InputField-id'
                                                iconAlignment='left'
                                                iconType='TrashRed'
                                                isIconEnabled
                                                variants='primaryWhiteNoBoader'
                                                onClick={() => setDelConf(true)}
                                            />
                                        </Typhography>
                                    )}
                                    <Typhography component='div'>
                                        <Button
                                            buttonLabel='Cancel'
                                            variants='primaryWhite'
                                            onClick={() => setWarningConf({ open: true, message: "You have changed the patient's information.\nAre you sure you don't want to save the changes?", type: '', index: 0 })}
                                        />
                                    </Typhography>
                                    <Typhography component='div'>

                                        {hasConsentGiven ? <Button
                                            type='submit'
                                            buttonLabel={!editMode ? 'Create patient' : 'Save'}
                                            additionalClasses={editMode ? 'px-6' : ''}
                                            data-testId='InputField-id'
                                            variants='primaryBlue'
                                            onClick={() => setIsFormSubmitted(true)}
                                        /> :
                                            <Button
                                                buttonLabel={'Collect consent'}
                                                additionalClasses={editMode ? 'px-6' : ''}
                                                data-testId='InputField-id'
                                                variants='primaryBlue'
                                                onClick={() => setShowPatientConsentModal(true)}
                                            />}
                                    </Typhography>
                                </Typhography>
                            </Typhography>
                        </Typhography>
                    </Typhography>
                </form>
            </div>
            {cropperOpen && (
                <ImageCropperModal
                    defaultImg={formik.values.imageId ? base64Image(avatarUrl) : ''}
                    updateImage={updateImage}
                    closeModal={(save: boolean) => closeCropper(save)}
                    header={editMode || !isEqual(avatarUrl, '') ? 'Change photo' : 'Upload photo'}
                />
            )}
            {imageDelConf && (
                <DialogBox
                    title='Confirmation !'
                    content='Are you sure you want to delete ?'
                    isConfirmButtonEnabled={true}
                    isCancelButtonEnabled={true}
                    handleConfirmButton={onClickHandleImageDelConf}
                    handleCancelButton={() => setImageDelConf(false)}
                    handleOuterClick={() => setImageDelConf(false)}
                    textConfirmButton={'Yes'}
                    textCancelButton={'No'}
                    dialogBoxState={imageDelConf}
                />
            )}
            {delConf && (
                <DeleteModal
                    rowData={patient}
                    isOpenModal={true}
                    handleCancelButton={() => setDelConf(false)}
                    handleConfirmButton={deletePatient}
                    avatarUrl={avatarUrl}
                />
            )}
            {warningConf.open && (
                <WarningModal
                    isOpenModal={true}
                    handleCancel={() => setWarningConf({ open: false, message: '', type: '', index: 0 })}
                    handleContinue={warningConf.type === 'extCaseMngr' ? handleRemoveManager : cancelAdd}
                    message={warningConf.message}
                    type={warningConf.type}
                    index={warningConf.index}
                />
            )}
            {showAddInfoModal && (
                <ReleaseInfoModal
                    isOpenModal={showAddInfoModal}
                    handleCancelButton={handleCloseModal}
                    handleConfirmButton={savePatientResource}
                    setViewReleaseInfoPdf={setViewReleaseInfoPdf}
                    setPdfReleaseInfoUrl={setPdfReleaseInfoUrl}
                    updateCaseManagerInfo={updateCaseManagerInfo}
                    currentResourcesInfo={currentResourcesInfo}
                    showPreview={(fileId: string, uploadType?: string) => previewfilesOnModal(fileId, uploadType)}
                    navigateToLetter={navigateToLetter}
                    currentExcmgIndex={currentExcmgIndex}
                    currentExistingRes={currentExistingRes}
                    uploadResourceInfoForm={props.uploadResourceInfoForm}
                    patientId={patientId}
                />
            )}
            {viewReleaseInfoPdf && pdfReleaseInfoUrl && (
                <PdfPreviewModal
                    signedUrl={pdfReleaseInfoUrl}
                    isOpenModal={viewReleaseInfoPdf}
                    handleCancelButton={handleCloseResourceModal}
                    title='Release of Information'
                />
            )}
            {showPatientConsentModal && (
                <PatientConsentModal
                    isOpen={showPatientConsentModal}
                    handleCancelButton={() => setShowPatientConsentModal(false)}
                    handleSaveConsent={(values: PatientConsent) => {
                        formik.setFieldValue('patientConsent', values);
                        setShowPatientConsentModal(false);
                    }}
                />
            )}
        </>
    );
};
const PatientAddComponent = patientHOC(PatientAdd);
export default PatientAddComponent;
