import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { patientAPI } from 'api/patientAPI';
import { IPatient } from 'utils/types/patient.type';

type InitialState = {
    data: { results: IPatient[]; total: number };
    isLoading: boolean;
    error: null | string;
    patientDraftValues?: IPatient | null;
};

const patientSlice = createSlice({
    name: 'patientApi',
    initialState: {
        data: { results: [], total: 0 },
        isLoading: false,
        error: null,
        patientDraftValues: null,
    } as InitialState,
    reducers: {
        savePatientDraftValues: (state, action) => {
            state.patientDraftValues = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(patientAPI.endpoints.patientList.matchFulfilled), (state, action) => {
                state.data = action.payload.data;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(patientAPI.endpoints.patientList.matchPending), (state) => {
                state.isLoading = true;
            })
            .addMatcher(isAnyOf(patientAPI.endpoints.patientList.matchRejected), (state, action) => {
                if (action.error.message) state.error = action.error.message;
                state.isLoading = false;
            });
    },
});

export const { savePatientDraftValues } = patientSlice.actions;

export default patientSlice.reducer;
