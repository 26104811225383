import { Icon, Typhography } from 'dfh-ui-library';
import { useEffect, useState } from 'react';

type PreviewProps = {
    content?: string;
    h?: string;
};

export const ImagePreview: React.FC<PreviewProps> = ({ content }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = content || '';
        img.onload = () => setIsLoading(false);

        setTimeout(() => {
            setIsLoading(false);
        }, 6000);
    }, [content]);
    return (
        <div className='w-full overflow-hidden h-full  relative'>
            {isLoading ? <LoadingProgress /> :
                <img src={content} alt='Preview' className='max-w-full ' />
            }
        </div>

    )
};

export const PdfPreview: React.FC<PreviewProps> = ({ content }) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Reset loading state when content changes
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 6000);
    }, [content]);


    return (
        <>
            <div className='w-full overflow-hidden h-full relative'>
                {/* Set width to full and height as needed, and hide overflow */}
                {isLoading && <LoadingProgress />}

                <object
                    data={content}
                    type='application/pdf'
                    className='w-full h-full'
                    onLoad={() => setIsLoading(false)}
                    onError={() => setIsLoading(false)}
                >
                    <p>Your browser does not support PDFs.</p>
                </object>
            </div>
        </>
    )
};

export const UrlPreview: React.FC<PreviewProps> = ({ content, h = 'h-full' }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Reset loading state when content changes
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 6000);
    }, [content]);

    if (isLoading) {
        return <LoadingProgress />
    }
    return (
        <div className={`w-full overflow-hidden ${h} flex justify-center`}>
            {/* Set width to full and height as needed, and hide overflow */}
            {/* <img src={content} alt='Preview' className='max-w-full h-60' /> */}
            <object data={content} type='application/pdf' className={`w-full ${h}`}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
            >

                <p>Your browser does not support PDFs.</p>
            </object>
        </div>
    )
};

export const DocxPreview: React.FC<PreviewProps> = ({ content }) => {
    return (
        <Typhography component='div' additionalClasses='flex flex-col items-center text-14s text-gray-700'>
            <Typhography> {content}</Typhography>
            <Typhography>
                <Icon iconType='EditDocument' />
            </Typhography>
        </Typhography>
    );
};

export const TextPreview: React.FC<PreviewProps> = ({ content }) => (
    <textarea value={content} readOnly className='w-full h-60' />
);


export const LoadingProgress = () => {
    return (
        <Typhography component='div' additionalClasses='absolute inset-0 flex items-center justify-center mt-2 text-[16px] text-[#21232C]'>
            Loading document preview...
        </Typhography>
    )
}