import { Button, IconInput, Message, Typhography } from 'dfh-ui-library';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ILoginProps } from 'utils/types/auth.type';
import loginHOC from '../index';
import { useLocation } from 'react-router-dom';
import { showToast } from 'utils/helpers';
import { useEffect, useState } from 'react';

const MfaVerify = (props: ILoginProps) => {
    const location = useLocation();
    const { session, email, password } = location.state;
    const [mfaParams, setMfaParams] = useState({
        session: session,
        email: email,
        password: password,
    });
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [countdown, setCountdown] = useState(30);

    useEffect(() => {
        setMfaParams({
            session: session,
            email: email,
            password: password,
        });
    }, [location.state]);

    useEffect(() => {
        // Start with disabled button
        setIsResendDisabled(true);

        // Start countdown
        const timer = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setIsResendDisabled(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [mfaParams]); // Reset timer when mfaParams changes (i.e., after resend)




    // Login form config
    const formik = useFormik({
        initialValues: {
            mfaCode: '',
        },
        validationSchema: Yup.object({
            mfaCode: Yup.string().required('MFA code is required'),
        }),
        onSubmit: async (values) => {

            const mfaVerifyResult = await props.apiMethods.mfaVerify({
                mfaCode: values.mfaCode,
                email: mfaParams.email,
                session: mfaParams.session
            });

            if (mfaVerifyResult && mfaVerifyResult.data) {
                formik.resetForm();
                if (!mfaVerifyResult.data.data.payload.email) {
                    // change to email_verified
                    props.navigateMethods.navigateToVerification(mfaVerifyResult.data.data.payload.email);
                } else if (mfaVerifyResult.data.data.payload?.fullName === undefined) {
                    props.navigateMethods.navigateToUserDetails(mfaVerifyResult.data.data.payload.email);
                } else {
                    props.navigateMethods.navigateToHome();
                }
            }
        },
    });

    const onBtResendMfa = async () => {
        const resendMfaResult = await props.apiMethods.login({
            email: email,
            password: password,
        }, true);
        if (resendMfaResult?.data) {
            formik.resetForm();
            showToast(resendMfaResult.data?.message || 'MFA code resent', 'success');
            setMfaParams({
                session: resendMfaResult.data.data.payload.session,
                email: resendMfaResult.data.data.payload.email,
                password: password,
            });
            setCountdown(30); // Reset countdown after successful resend
            setIsResendDisabled(true);
        }

    }

    return (
        <>
            {props.errorMsg.isVisible && <Message type={props.errorMsg.type} text={props.errorMsg.msg} />}

            <Typhography type='authHeader' data-testid="login-title">Verify identity</Typhography>
            <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                <Typhography>
                    <Typhography type='textMediumGray'>
                        You've got mail. Check your email for an OTP code to confirm your identity and finish logging in
                    </Typhography>
                </Typhography>
            </Typhography>
            <form onSubmit={formik.handleSubmit}>
                <Typhography component='div' additionalClasses='grid grid-cols-1 mt-4'>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography component='div'>
                            <IconInput
                                iconClassLeft={`top-5 left-4 ${formik.values.mfaCode ? '' : 'opacity-50'}`}
                                isIconLeft
                                leftIcon='keyIcon'
                                label='OTP Code'
                                placeholder='000000'
                                data-testid="mfa-input"
                                name='mfaCode'
                                value={formik.values.mfaCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.mfaCode && formik.errors.mfaCode ? formik.errors.mfaCode : ''}
                                isAdditionalErrorInput={true}
                                additionalErrorClasses='text-red-500 text-xs pt-1'
                                additionalClasses={formik.errors.mfaCode ? 'border-red-500' : ''}
                                isBorderedError={formik.errors.mfaCode ? true : false}
                            />
                        </Typhography>
                    </Typhography>
                    <Typhography component='div' additionalClasses='py-2'>
                        <Typhography additionalClasses='flex justify-end relative'>
                            <Typhography type="inforTextGray" additionalClasses='text-sm'>
                                Didn't receive the code?
                            </Typhography>
                            <Typhography
                                // type='linkTextNormal'
                                onClick={onBtResendMfa}
                                type="linkTextNormal"
                                disabled={isResendDisabled}
                                additionalClasses='cursor-pointer ml-1 text-btn-main font-medium leading-5'
                            // onClick={props.navigateMethods.navigateToResetPassword}
                            >
                                {isResendDisabled ? `Resend (${countdown}s)` : 'Resend'}

                            </Typhography>

                        </Typhography>
                    </Typhography>

                    <Typhography additionalClasses='my-8'>
                        <Button
                            type='submit'
                            buttonLabel='Finish login'
                            variants='primaryBlue'
                            data-testid="login-button"
                            additionalClasses='bg-[#006CD0]'
                            isDisabled={!(formik.isValid && formik.dirty)}
                            isLoading={props.isLoading}
                        />
                    </Typhography>

                </Typhography>
            </form>
        </>
    );
};

const MfaVerifyPageComponent = loginHOC(MfaVerify);
export default MfaVerifyPageComponent;
