import * as Yup from 'yup';

export const validation = {
    firstNameValidation: Yup.string()
        .test('is-not-blank', 'First name is required', (value) =>
            value ? value.trim().length > 0 : false
        )
        .matches(/^[A-Za-z\s]+$/, 'First name should contain only alphabetic characters and spaces')
        .max(35, 'First name must be 35 characters or less'),
    lastNameValidation: Yup.string()
        .test('is-not-blank', 'Last name is required', (value) =>
            value ? value.trim().length > 0 : false
        )
        .matches(/^[A-Za-z\s]+$/, 'Last name should contain only alphabetic characters and spaces')
        .max(35, 'Last name must be 35 characters or less'),
    // firstNameValidation: Yup.string()
    //     .matches(/^[A-Za-z\s]+$/, 'First name should contain only alphabetic characters and spaces')
    //     .max(35, 'First name must be 35 characters or less')
    //     .required('First name is required'),
    // lastNameValidation: Yup.string()
    //     .matches(/^[A-Za-z\s]+$/, 'Last name should contain only alphabetic characters and spaces')
    //     .max(35, 'Last name must be 35 characters or less')
    //     .required('Last name is required'),
    emailValidation: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address'),
};
