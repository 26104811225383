import { AppConfig } from '../utils/types/appConfig.type';

const config: AppConfig = {
    api: {
        // baseUrl:  'https://api.docsforhealth.org/api/v1',
        // Dev API
        baseUrl: 'https://yjxxnk58e9.execute-api.us-east-1.amazonaws.com/api/v1',
        // baseUrl: 'http://localhost:3000/api/v1',
        // Staging API
    //    baseUrl: 'https://rlc2uoo4u7.execute-api.us-east-1.amazonaws.com/api/v1'
    },
};

export default config;
