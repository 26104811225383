import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from './store';

interface IPdfState {
    pdf?: string;
}

const initialState: IPdfState = {
    pdf: ""
}

export const solutionNoteSlice = createSlice({
    name: "pdf",
    initialState,
    reducers: {
        setPdf: (state: IPdfState, action: PayloadAction<string>) => {
            state.pdf = action.payload;
        },
    }
})

export const { setPdf } = solutionNoteSlice.actions;
export const getPdf = (state: RootState) => state.pdf.pdf;
export default solutionNoteSlice.reducer;