import { Button, Heading, Icon, Input, Modal, Select, Typhography } from 'dfh-ui-library';
import { ChangeEvent, useEffect, useState } from 'react';
import { IFormResoureseData, IPatient } from 'utils/types/patient.type';
import { DocxPreview, ImagePreview, PdfPreview, TextPreview } from './imagePreview';
import _, { isEmpty } from 'lodash';
import { IPatientResourceRequest } from 'utils/types/image.type';
import Dropzone from 'react-dropzone'
import { WarningCircleIcon } from '../helpers/iconHelper';

interface FormUploadModalProps {
    rowData: IPatient | undefined;
    isOpenModal: boolean;
    handleCancelButton: () => void;
    avatarUrl?: string;
    handleConfirmButton?: (req: IPatientResourceRequest) => void;
    editData?: IFormResoureseData;
    isNewDoc: boolean;
    setIsNewDoc?(value: boolean): void;
    resourceTypes?: string[];
    loading: boolean;
}

const alphaNumericRegex = /^[a-z0-9\s]+$/i;

export const FormUploadModal = (formProps: FormUploadModalProps) => {
    const [isOpen, _setIsOpen] = useState(formProps.isOpenModal);
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);
    const [fileNme, setFileName] = useState<string | null>(null);
    const [documentName, setDocumentName] = useState<string | undefined>();
    const [resourceType, setResourceType] = useState<string | null>(null);
    const [resourceFile, setResourceFile] = useState<File | null>(null);
    const [labelText, setLabelText] = useState<string>('Enter Your Document Type')
    const [otherResourceName, setOtherResourceName] = useState<string>('')
    const isTextBoxDisabled = !isEmpty(formProps?.editData?.signedUrl);
    // const inputFieldAdditionalClasses = isTextBoxDisabled ? 'text-gray-500' : ''
    const isEditData = !isEmpty(formProps?.editData);

    const handleFileChange = (file: File | null) => {
        file?.name && setFileName(file?.name);
        if (file) {
            setResourceFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string | null;
                if (result) {
                    setFileContent(result);
                    setFileType(file.type);
                }
            };
            if (file.type.startsWith('image/')) {
                reader.readAsDataURL(file);
            } else if (file.type === 'application/pdf') {
                reader.readAsDataURL(file);
            } else if (file.type.startsWith('text/')) {
                reader.readAsText(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                reader.readAsArrayBuffer(file); // read .docx file as ArrayBuffer
            }
        }
    };

    const handleChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const { value } = event.target;
        if (!_.isEmpty(value)) {
            setResourceType(value);
            return;
        }
        setResourceType('');
    };

    const handleDocumentNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        // Only accept input if it's alphanumeric and within 100 characters
        if ((alphaNumericRegex.test(value) || isEmpty(value)) && value.length <= 100) {
            setDocumentName(value);
        }
    };
    const uploadResources = () => {

        formProps.handleConfirmButton &&
            resourceFile &&
            resourceType &&
            formProps.handleConfirmButton({
                resourceId: formProps?.editData?._id,
                file: resourceFile,
                resourceName: resourceType !== 'Other' ? resourceType : otherResourceName,
                resourceDocName: documentName
            });
    };

    useEffect(() => {
        if (isEditData) {
            setLabelText('Document Type')
        } else {
            setLabelText('Enter Your Document Type')
        }
    }, [isEditData])

    const renderPreview = () => {
        if (!fileType || !fileContent) return null;

        switch (true) {
            case fileType.startsWith('image/') || fileType === 'jpeg' || fileType === 'png' || fileType === 'jpg':
                return <ImagePreview content={fileContent} />;
            case fileType === 'application/pdf' || fileType === 'pdf':
                return <PdfPreview content={fileContent} />;
            case fileType.startsWith('text/'):
                return <TextPreview content={fileContent} />;
            case fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return fileNme && <DocxPreview content={fileNme} />;
            case fileType === 'docx':
                return <DocxPreview />;
            default:
                // return <p>File type not supported for preview.</p>;
                return (
                    <Typhography component='div' additionalClasses='flex flex-col items-center justify-center'>
                        <WarningCircleIcon />
                        <Typhography component='div' additionalClasses='mt-2 text-[16px] text-[#21232C]'>
                            File type not supported for preview.
                        </Typhography>
                        <Typhography component='div' additionalClasses='text-[12px] text-[#636D77] mt-2' >
                            .xslx is not a supported document type
                        </Typhography>
                    </Typhography>
                )
        }
    };

    useEffect(() => {
        function getFileType(url: string) {
            const extension = url.split('.').pop();
            return extension;
        }

        const editData = formProps?.editData;
        // setShowFileUpload(true);
        // setResourceFile(null);
        setFileContent(null);
        if (editData) {
            let type: string | undefined = '';
            if (editData.resourceDocName || editData.resourceName) {
                setDocumentName(editData.resourceDocName || editData.resourceName)
            }

            if (editData.signedUrl) {
                type =
                    editData?.resourceLink && editData?.resourceLink !== undefined
                        ? getFileType(editData.resourceLink)
                        : '';
                if (type) {
                    setFileType(type);
                }
                setFileContent(editData.signedUrl);
            }

            if (formProps.resourceTypes && editData.resourceName) {
                if (formProps.resourceTypes.includes(editData.resourceName)) {
                    setResourceType(editData.resourceName)
                } else {
                    setResourceType('Other');
                    setOtherResourceName(editData.resourceName)
                }

            }

        }
    }, [formProps.editData, formProps.isOpenModal]);

    useEffect(() => {
        if (formProps.isNewDoc) {
            setResourceType(null)
            setOtherResourceName('')
        }
    }, [formProps.isNewDoc]);

    const handleOtherResourceNameInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        // Only accept input if it's alphanumeric and within 120 characters
        if ((alphaNumericRegex.test(value) || isEmpty(value)) && value.length <= 120) {
            setOtherResourceName(value);
        }
    }

    const typesList = formProps.resourceTypes;
    return (
        <Modal
            isOpen={isOpen}
            onClose={false}
            modalAdditionalClasses={'max-w-[80vw] max-h-[80vh] h-full w-full flex flex-col'}
        >
            <Typhography component='div' additionalClasses='flex justify-between pt-4 px-4'>
                <Typhography component='div'>
                    <Heading type='h2' colorType='dark' label={formProps.isNewDoc ? 'Add a new document' : `Editing ${documentName || 'document'}`} additionalClasses='mb-4' />
                </Typhography>
                <Typhography component='div'>
                    <Icon iconType='closeModal' additionalClasses='' onClick={formProps.handleCancelButton} />
                </Typhography>
            </Typhography>
            <Typhography component='div' additionalClasses='flex-grow border border-x-0 overflow-auto'>
                <Typhography component='div' additionalClasses='grid grid-cols-4  h-full' >
                    <Typhography component='div' additionalClasses='flex flex-col p-4 gap-4 col-span-2'>

                        <Typhography>
                            <Input
                                name='documentName'
                                label="Document Name"
                                isRequired
                                labelType='formLabelMedeum'
                                value={documentName ?? ''}
                                onChange={handleDocumentNameChange}
                                // disabled={isTextBoxDisabled ? true : false}
                                // additionalClasses={`${inputFieldAdditionalClasses}`}
                                maxLength={100}
                            />
                            <Typhography component='span' additionalClasses='text-xs text-gray-500 mt-1'>
                                {(documentName?.length ?? 0)}/100 characters
                            </Typhography>
                        </Typhography>

                        {/* {(formProps.isNewDoc || (!formProps.isNewDoc && resourceType !== 'Other')) && ( */}
                        <Typhography component='div' additionalClasses=''>
                            <Select
                                name='resourceType'
                                onChange={handleChange}
                                onBlur={() => { }}
                                value={resourceType ?? ''}
                                defaultText='Select Type'
                                selectType='regular'
                                label={`Document Type`}
                                isRequired
                                labelClasses='mb-1'
                                isIcon={false}
                                labelType='formLabelMedeum'
                                options={
                                    typesList &&
                                    typesList.map((types) => ({
                                        displayName: types,
                                        value: types,
                                    })).concat([{ displayName: 'Other', value: 'Other' }])
                                }
                                disabled={isTextBoxDisabled}
                                additionalClasses='py-3'
                            />{' '}
                        </Typhography>
                        {/* // )} */}
                        {resourceType === 'Other' && (
                            <Typhography>
                                <Input
                                    name='otherResource'
                                    label={labelText}
                                    isRequired
                                    labelType='formLabelMedeum'
                                    value={otherResourceName}
                                    onChange={handleOtherResourceNameInputChange}
                                    disabled={isTextBoxDisabled}
                                    // additionalClasses={`${inputFieldAdditionalClasses}`}
                                    maxLength={120}
                                />
                                <Typhography component='span' additionalClasses='text-xs text-gray-500 mt-1'>
                                    {otherResourceName.length}/120 characters
                                </Typhography>
                            </Typhography>
                        )}
                        {/* <Typhography component='div' additionalClasses='relative'>
                            {(formProps.isNewDoc || showFileUpload) && (
                                <CustomFileInput
                                    iconType='BlackUpload'
                                    iconAlignment='left'
                                    isIconEnabled={true}
                                    buttonClasses={`rounded-md border-dashed border-2`}
                                    buttonBgClass='bg-[#F1F5F9]'
                                    textColor='text-black-540'
                                    onFileChange={handleFileChange}
                                />
                            )}

                            {!formProps.isNewDoc && !showFileUpload && (
                                <Typhography component='div' additionalClasses='absolute top-6 right-4 cursor-pointer'>
                                    <div className='bg-blue-550 rounded-full p-2'>
                                        <CloseIcon
                                            onClick={() => {
                                                setShowFileUpload(true);
                                            }}
                                        />
                                    </div>
                                </Typhography>
                            )}
                        </Typhography> */}

                        {
                            fileContent && (
                                <Dropzone noClick onDrop={acceptedFiles => handleFileChange(acceptedFiles[0])}>
                                    {({ getRootProps, getInputProps, isDragActive, open }) => (
                                        <Typhography
                                            component='div'
                                            additionalClasses={`border border-dashed flex flex-col justify-center items-center rounded-lg ${isDragActive
                                                ? 'border-[#006CD0] bg-[#EFF6FF]'
                                                : 'border-[#CFD8DF] bg-[#F9FAFB]'
                                                }  p-8 cursor-pointer transition-colors duration-200`}
                                            {...getRootProps()}
                                            
                                        >
                                            <Typhography component='div' additionalClasses='flex flex-col items-center justify-center'>
                                                <input {...getInputProps()} />
                                                <Typhography component='div' additionalClasses='mt-2 text-[16px] text-[#21232C]'>
                                                    {isDragActive ? 'Drop the file here' : (
                                                        <>
                                                            Drag and Drop or&nbsp;
                                                            <Typhography onClick={open} component='span' additionalClasses='text-[#006CD0] cursor-pointer'>Choose file</Typhography> &nbsp;to replace document
                                                        </>
                                                    )}
                                                </Typhography>
                                                <Typhography component='div' additionalClasses='text-[12px] text-[#636D77] mt-2' >
                                                    &nbsp;.pdf, .jpg, .png
                                                </Typhography>
                                            </Typhography>
                                        </Typhography>
                                    )}
                                </Dropzone>
                            )
                        }

                    </Typhography>
                    <Typhography component='div' additionalClasses='col-span-2 p-4 h-full'>
                        {(formProps.isNewDoc && !fileContent) ?
                            // Show file uploader
                            <Dropzone noClick onDrop={acceptedFiles => handleFileChange(acceptedFiles[0])}>
                                {({ getRootProps, getInputProps, isDragActive, open }) => (
                                    <Typhography
                                        component='div'
                                        additionalClasses={`border border-dashed flex flex-col justify-center items-center rounded-lg ${
                                            isDragActive 
                                                ? 'border-[#006CD0] bg-[#EFF6FF]' 
                                                : 'border-[#CFD8DF] bg-[#F9FAFB]'
                                        } h-full p-2 cursor-pointer transition-colors duration-200`}
                                        {...getRootProps()}
                                    >
                                        <Typhography component='div' additionalClasses='flex flex-col items-center justify-center'>
                                            <input {...getInputProps()} />
                                            <Icon iconType='Upload' iconDefaultClasses={`${isDragActive ? '!text-[#006CD0]' : '!text-[#A5B1BC]'}  !w-[24px] !h-[24px]`} />
                                            <Typhography component='div' additionalClasses='mt-2 text-[16px] text-[#21232C]'>
                                                {isDragActive ? 'Drop the file here' : (
                                                    <>
                                                        Drag and Drop or&nbsp;
                                                        <Typhography onClick={open} component='span' additionalClasses='text-[#006CD0] cursor-pointer'>Choose file</Typhography> &nbsp;to upload
                                                    </>
                                                )}
                                            </Typhography>
                                            <Typhography component='div' additionalClasses='text-[12px] text-[#636D77] mt-2' >
                                                &nbsp;.pdf, .jpg, .png
                                            </Typhography>
                                        </Typhography>
                                    </Typhography>
                                )}
                            </Dropzone>
                            :
                            <Typhography component='div' additionalClasses='border flex flex-col justify-center items-center rounded-lg border-[#CFD8DF] bg-[#F9FAFB] h-full p-2 '>
                                {renderPreview()}

                            </Typhography>}
                    </Typhography>
                </Typhography>
            </Typhography>

            <Typhography component='div' additionalClasses='flex justify-between flex-row gap-4 mt-4 pb-4 px-4'>
                <Typhography component='div'>
                    <Button
                        buttonLabel='Cancel'
                        data-testId='InputField-id2'
                        variants='primaryWhite'
                        onClick={formProps.handleCancelButton}
                    />
                </Typhography>
                <Typhography component='div'>
                    <Button
                        buttonLabel='Upload'
                        data-testId='InputField-id'
                        variants='primaryBlue'
                        onClick={uploadResources}
                        isDisabled={resourceFile === null || resourceType === null || formProps.loading}
                    />
                </Typhography>
            </Typhography>
        </Modal >
    );
};