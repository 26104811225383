type language = {
    sdBlank: string;
    solution: {
        badgeTitle: string;
        challengeResolved: string;
        challengeRefer: string;
        challengeLetterStart: string;
        challengeLetterComplete: string;
    };
    list: {
        tooltipChallenges: string;
        tooltipUrgent: string;
    };
    add: {
        releaseInfo: {
            modalHeader: string;
        };
    };
};

export const language: language = {
    sdBlank: 'This patient has not yet completed Social Determinants Screening',
    solution: {
        badgeTitle: 'solutions',
        challengeResolved: 'Challenge to resolve',
        challengeRefer: 'Completing solution for challenge',
        challengeLetterStart: 'Starting letter for challenge',
        challengeLetterComplete: 'Completing letter for challenge'
    },
    list: {
        tooltipChallenges: 'total unresolved challenges',
        tooltipUrgent: 'urgent challenges',
    },
    add: {
        releaseInfo: {
            modalHeader: 'Add Release of Information',
        },
    },
};
